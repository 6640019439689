import React from "react";

import PageOne from "./Pages/PageOne";
// import PageTwo from "./Pages/PageTwo";
// import PageThree from "./Pages/PageThree";
import PageFour from "./Pages/PageFour";
// import PageFive from "./Pages/PageFive";
// import PageEight from "./Pages/PageEight";
import PageNine from "./Pages/PageNine";

import { HeaderSize } from "../../Constants/global";

import './Careers.less'

const Careers = () => {
  return (
    <div className="careerPageStyle" style={{ paddingTop: HeaderSize.BottomPad }}>
      <PageOne />
      {/* <PageTwo /> */}
      {/* <PageThree /> */}
      <PageFour />
      {/* <PageFive /> */}
      {/* <PageEight /> */}
      <PageNine />
    </div>
  );
};

export default Careers;
