import React from "react";
import revenueShareImg from "../../../Assets/revenue-share.webp";
import { useNavigate } from "react-router-dom";
import "./PageOne.less";

import { useWindowSize } from "../../Commons/Common";
const PageOne = () => {
  const [width, height] = useWindowSize();
  let navigate = useNavigate();

  return (
    <div className="sPageOne_bg" style={{ overflow: "hidden" }}>
      <div className="aPageOne_row">
        <div
          className="aPageOne_colOne"
          data-aos={width < 992 ? "fade-down" : "fade-right"}
        >
          <div className="sPageOne_Italic">WE ARE HERE</div>
          <div className="sPageOne_col_bigsShow">
            <div className="sPageOne_title_one">
              TO INCREASE <br />
              YOUR REVENUE
            </div>
            <div style={{ marginTop: 10 }}>
              <span className="sPageOne_title_two">THROUGH</span>
              <span className="sPageOne_title_three"> OUR GAMES</span>
            </div>
          </div>
          <div className="sPageOne_col_smallsShow">
            <div className="sPageOne_title_one">
              TO INCREASE YOUR <br />
              REVENUE THROUGH
            </div>
            <div style={{  }}>
              <span className="sPageOne_title_three"> OUR GAMES</span>
            </div>
          </div>
          <div className="sPageOne-box">
            <div className="sPageOne-box_text"
                  onClick={() => {
                    // navigate("/contact-us");
                    window.open('/contact-us','_blank')
                    // window.scrollTo(0, 0);
                  }}>LET'S GET STARTED</div>
          </div>
        </div>
        <div
          className="aPageOne_colTwo"
          data-aos={width < 992 ? "fade-up" : "fade-left"}
        >
          <div className="sPageOne_img">
            <img
              src={revenueShareImg}
              alt="imgBtn"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageOne;
