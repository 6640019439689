import React from "react";
import mobileImg from "../../../Assets/portal-solutions.webp";
import standaloneImg from "../../../Assets/standalone-solutions.webp";
import oneImg from "../../../Assets/1.webp";
import twoImg from "../../../Assets/2.webp";

import "./PageTwo.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";

const PageTwo = () => {
  const [width] = useWindowSize();

  return (
    <div className="sPageTwo_bg" style={{ overflow: "hidden" }}>
      <div className="sPageTwo_Heading">ADD GAMES TO YOUR PLATFORM</div>
      <div className="sPageTwo_hBar_wrapper">
        <div className="sPageTwo_hBar">
          <div className="sPageTwo_hEl" />
        </div>
      </div>
      <div className="sPageTwo_Body">
        <Row justify="space-evenly" gutter={[width <= 576 ? 20 : 30, width <= 576 ? 55 : 90]} align="middle">
          <Col
            xs={18}
            sm={16}
            md={14}
            lg={10}
            xl={10}
            align="flex-start"
            data-aos={width < 992 ? "fade-up" : "fade-right"}
          >
            <div className="sPageTwo_img">
              <a href={"https://gameskite.com/?id=portal"} target="_blan">
                <img
                  src={mobileImg}
                  alt="imgBtn"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </a>
            </div>
          </Col>
          <Col
            xs={24}
            sm={22}
            md={18}
            lg={14}
            xl={14}
            align="flex-start"
            data-aos={width < 992 ? "fade-up" : "fade-left"}
          >
            <span className="sPageTwo_card_sNumber">
              <img style={{ width: "70%", height: "70%" }} src={oneImg} alt="oneImg" className="aPageTwo_numImg" />
            </span>
            <span className="sPageTwo_card_heading">PORTAL SOLUTIONS</span>

            <div className="sPageTwo_Card">
              Our portal solution allows you to gain access to the extensive
              library of ready-made HTML5 games. With the portal solution you can add
              immersive games to your existing platform without making any UI
              changes, especially for the games.
            </div>
          </Col>
        </Row>
        {
          width <= 576 ?
          (<div>
            <br />
            <br />
            {/* <br /> */}
          </div>)
          :
          (<div>
            <br />
            <br />
            <br />
            {/* <br /> */}
          </div>)
        }
        <Row justify="center" gutter={[width <= 576 ? 20 : 0, width <= 576 ? 55 : 0]} align="middle">
          <Col
            xs={{ order: 2, span: 24 }}
            sm={{ order: 2, span: 22 }}
            md={{ order: 2, span: 18 }}
            lg={{ order: 1, span: 14 }}
            xl={{ order: 1, span: 14 }}
            align="flex-start"
            data-aos={width < 992 ? "fade-up" : "fade-right"}
          >
            <span className="sPageTwo_card_sNumber">
              <img style={{ width: "70%", height: "70%" }} src={twoImg} alt="oneImg" className="aPageTwo_numImg" />
            </span>
            <span className="sPageTwo_card_heading">STANDALONE GAMES</span>
            <div className="sPageTwo_Card">
              We have over 100+ exciting HTML5 games on our portal that keep updating at regular intervals. But if you want to embed only a few games on your platform, then we can also provide you direct links to individual games.
            </div>
          </Col>
          <Col
            xs={{ order: 1, span: 18 }}
            sm={{ order: 1, span: 16 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 2, span: 8 }}
            xl={{ order: 2, span: 7 }}
            align="center"
            data-aos={width < 992 ? "fade-up" : "fade-left"}
          >
            <div className="sPageTwo_img">
              <img
                src={standaloneImg}
                alt="imgBtn"
                style={{ width: width <= 576 ? "110%" : "120%", height: "100%", objectFit: "cover", marginLeft: width <= 576 ? "" : -70, marginTop: width <= 576 ? "" : -100}}
              />
            </div>{" "}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PageTwo;
