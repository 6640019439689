import React from "react";

import "./PageThree.less";
import { Row, Col } from "antd";

const PageThree = () => {
  return (
    <div className="aPageThree_bg">
      <div className="aPageThree_heading">GAMES WE OFFER</div>
      <div className="aPageThree_hBar_wrapper">
        <div className="aPageThree_hBar">
          <div className="aPageThree_hEl" />
        </div>
      </div>
      <div className="content">
        <Row
          justify="center"
          align="center"
          style={{ padding: "0 15px" }}
          data-aos="fade-up"
        >
          <Col xs={24} sm={23} md={22} lg={20} xl={16}>
            <div className="aPageThree_text">
              Our HTML5 games are developed and optimized to perform well on
              various platforms such as Androids, IOS, Web, etc. If we talk about
              genre, Freak X Games develops a variety of HTML5 games like arcade,
              action, casual, sports, puzzles and many more.
            </div>
          </Col>
        </Row>
      </div>


      <div className="content">
        <Row
          justify="center"
          align="center"
          style={{ padding: "0 15px" }}
          data-aos="fade-up"
        >
          <Col xs={24} sm={23} md={22} lg={20} xl={16}>
            <div className="aPageThree_text" style={{ marginTop: 20 }}>
              If you want to license our HTML5 games for your business and make
              them available on your platform, contact us.
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PageThree;
