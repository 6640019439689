import React, { useState } from "react";

import html5Img from "../../../Assets/Html5_D.webp";
import hHtml5Img from "../../../Assets/Html5_L.webp";
import worldWideImg from "../../../Assets/Worldwide_D.webp";
import hWorldWideImg from "../../../Assets/Worldwide_L.webp";
import businessImg from "../../../Assets/Business_D.webp";
import hBusinessImg from "../../../Assets/Business_L.webp";
import supportImg from "../../../Assets/Support_D.webp";
import hSupportImg from "../../../Assets/Support_L.webp";

import "./PageFour.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";

const benifitData = [
  {
    key: "1",
    image: businessImg,
    hImage: hBusinessImg,
    heading: "5+",
    subHeading: "Years in Business",
  },
  {
    key: "2",
    image: html5Img,
    hImage: hHtml5Img,
    heading: "100+",
    subHeading: "HTML5 Games",
  },
  {
    key: "3",
    image: worldWideImg,
    hImage: hWorldWideImg,
    heading: "150+",
    subHeading: "Worldwide Clients",
  },
  {
    key: "4",
    image: supportImg,
    hImage: hSupportImg,
    heading: "95%",
    subHeading: "Retention Rate",
  },
];

const PageFour = () => {
  const [width, height] = useWindowSize();
  const [activeIndex, setActiveIndex] = useState(false);
  const [activeIndexPosition, setActiveIndexPosition] = useState();

  return (
    <div className="lPageFour_bg">
      <div className="lPageFour_Heading">
        WHY WORK WITH {width < 567 ? <br /> : ""} FREAK X GAMES?
      </div>
      <div className="lPageFour_hBar_wrapper">
        <div className="lPageFour_hBar">
          <div className="lPageFour_hEl" />
        </div>
      </div>
      <div className="pageFour_cardsDiv">
        <Row
          justify="space-evenly"
          gutter={[20, 20]}
          className="lPageFour_margin_row"
          data-aos="fade-up"
        >
          <div className="lPageFour_Bg">
            {/* <div className="lPageFour_hEl" /> */}
          </div>
          {benifitData &&
            benifitData.map((item) => {
              return (
                <>
                  <Col
                    key={item.key}
                    onMouseEnter={() => {
                      setActiveIndex(true);
                      setActiveIndexPosition(item.key);
                    }}
                    onMouseLeave={() => setActiveIndex(false)}
                    data-aos="zoom-in"
                    style={{
                      marginTop: "50px"
                    }}
                  >
                    <div className="lPageFour_card">
                      <div
                        className={
                          activeIndex === true && activeIndexPosition === item.key
                            ? "lPageFour_card_cContent"
                            : ""
                        }
                      />
                      <div className="lPageFour_card_img">
                        <img
                          src={
                            activeIndex === true &&
                              activeIndexPosition === item.key
                              ? item.hImage
                              : item.image
                          }
                          alt={`${item.image}Btn`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          key={item.key}
                        />
                      </div>
                      <div className="lPageFour_card_heading">{item.heading}</div>
                      <div className="lPageFour_card_sHeading">
                        {item.subHeading}
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
        </Row>
      </div>
    </div>
  );
};

export default PageFour;
