import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper";

import battleImg from "../../../Assets/20+-Battle-mode D.webp";
import hBattleImg from "../../../Assets/20+-Battle-mode_L.webp";
import userIncImg from "../../../Assets/user-enagagement_D.webp";
import hUserIncImg from "../../../Assets/user-enagagement_L.webp";
import costEffectiveImg from "../../../Assets/cost-effective_D.webp";
import hCostEffectiveImg from "../../../Assets/cost-effective_L.webp";
import addtionRImg from "../../../Assets/additional-revenue_D.webp";
import hAddtionRImg from "../../../Assets/additional-revenue_L.webp";
import locatlizedImg from "../../../Assets/localised-games_D.webp";
import hLocalizedImg from "../../../Assets/localised-games_L.webp";

import "./PageFour.less";
import { Row, Col } from "antd";

const benifitData = [
  {
    key: "1",
    image: battleImg,
    hImage: hBattleImg,
    heading: "20+ Battle Mode",
    subHeading: "Games",
  },
  {
    key: "2",
    image: userIncImg,
    hImage: hUserIncImg,
    heading: "Increase User",
    subHeading: "Engagement",
  },
  {
    key: "3",
    image: costEffectiveImg,
    hImage: hCostEffectiveImg,
    heading: "Cost Effective",
    subHeading: "Solution",
  },
  {
    key: "4",
    image: addtionRImg,
    hImage: hAddtionRImg,
    heading: "Additional",
    subHeading: "Revenue",
  },
  {
    key: "5",
    image: locatlizedImg,
    hImage: hLocalizedImg,
    heading: "100+ Localized",
    subHeading: "Games",
  },
];

const PageFour = () => {
  const [activeIndex, setActiveIndex] = useState(false);
  const [activeIndexPosition, setActiveIndexPosition] = useState();

  return (
    <div className="sPageFour_bg" style={{ overflow: "hidden" }}>
      <div className="sPageFour_Heading">BENEFITS OF REVENUE SHARE</div>
      <div className="sPageFour_hBar_wrapper">
        <div className="sPageFour_hBar">
          <div className="sPageFour_hEl" />
        </div>
      </div>
      <div className="sPageFour_Body">
        <div className="spageFour_BodyStrip"></div>
        <Row
          justify="space-evenly"
          gutter={[40, 20]}
          className="sPageFour_fcontent"
          data-aos="fade-up"
        >
          {benifitData &&
            benifitData.map((item) => {
              return (
                <>
                  <Col
                    key={item.key}
                    onMouseEnter={() => {
                      setActiveIndex(true);
                      setActiveIndexPosition(item.key);
                    }}
                    onMouseLeave={() => setActiveIndex(false)}
                    data-aos="zoom-out"
                  >
                    <div className="sPageFour_card">
                      <div
                        className={
                          activeIndex === true && activeIndexPosition === item.key
                            ? "sPageFour_card_cContent"
                            : ""
                        }
                      />
                      <div className="sPageFour_card_img">
                        <img
                          src={
                            activeIndex === true &&
                              activeIndexPosition === item.key
                              ? item.hImage
                              : item.image
                          }
                          alt={`${item.image}Btn`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="sPageFour_card_heading">{item.heading}</div>
                      <div className="sPageFour_card_sHeading">
                        {item.subHeading}
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
        </Row>
      </div>
      
      <div className="spageFour_BodyStrip2"></div>
      <Swiper
        data-aos="fade-up"
        slidesPerView={2}
        // spaceBetween={0}
        slidesPerGroup={1}
        loop={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loopFillGroupWithBlank={true}
        // pagination={{
        //   clickable: true,
        //   bulletClass: "swiper-pagination-bullet",
        //   bulletActiveClass: "swiper-pagination-bullet-active",
        // }}
        modules={[Autoplay]}
        className="sPageFour_mySwiper"

      // centeredSlides={true}
      >
        {benifitData &&
          benifitData.map((item) => {
            return (
              <SwiperSlide>
                <div className="sPageFour_SwiperContainer">
                  <div
                    key={item.key}
                    onMouseEnter={() => {
                      setActiveIndex(true);
                      setActiveIndexPosition(item.key);
                    }}
                    onMouseLeave={() => setActiveIndex(false)}
                  >
                    <div className="sPageFour_card">
                      <div
                        className={
                          activeIndex === true &&
                            activeIndexPosition === item.key
                            ? "sPageFour_card_cContent"
                            : ""
                        }
                      />
                      <div className="sPageFour_card_img">
                        <img
                          src={
                            activeIndex === true &&
                              activeIndexPosition === item.key
                              ? item.hImage
                              : item.image
                          }
                          alt={`${item.image}Btn`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="sPageFour_card_heading">
                        {item.heading}
                      </div>
                      <div className="sPageFour_card_sHeading">
                        {item.subHeading}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default PageFour;
