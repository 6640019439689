import React from "react";

import IconsAnimation from "../../Commons/IConsAnimation";

import "./PageFive.less";
import { useWindowSize } from "../../Commons/Common";

const PageFive = () => {
  const [width, height] = useWindowSize();

  return (
    <div>
      <div className="lPageFive_bg" style={{ overflow: "hidden" }}>
        <div className="lPageFive_heading">
          OUR GAMES ARE {width < 576 && <br />} LIVE ON!
        </div>
        <div className="lPageFive_hBar_wrapper">
          <div className="lPageFive_hBar">
            <div className="lPageFive_hEl" />
          </div>
        </div>
        <IconsAnimation />
      </div>
    </div>
  );
};

export default PageFive;
