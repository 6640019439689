import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import { Pagination } from "swiper";

// import actionImg from "../../../Assets/CPL Tounament.webp";
// import racingImg from "../../../Assets/Guns and Bottles.webp";
import activeImage from "../../../Assets/testimonial-shape-1.webp";
import passiveImage from "../../../Assets/testimonial-shape.webp";

import partner1_Img from "../../../Assets/Partners/Crazy-games.webp";
import partner2_Img from "../../../Assets/Partners/Games-distribution.webp";
import partner3_Img from "../../../Assets/Partners/Jagran-media.webp";
import partner4_Img from "../../../Assets/Partners/jio-games.webp";
import partner5_Img from "../../../Assets/Partners/mx-player.webp";

import quotationImg from "../../../Assets/Quotation.webp";
import activeQuotationImg from "../../../Assets/Quotation-1.webp";
import "./PageNine.less";
// import client from "../../../Assets/Game-Distribution.webp";

import { useWindowSize } from "../../Commons/Common";
import Column from "antd/lib/table/Column";
// import FadeInOut from "react-native-fade-in-out";

const rData = [
  // 1 - Crazy-games  
  {
    key: "1",
    content:
      `"We have worked with Freak X Games to launch several titles on CrazyGames and everything has gone smoothly. Their games exceeded a million plays on our platform in less than a year, which is a great achievement. We look forward to more successful games and our future collaboration!"`,
    image: partner1_Img,
    name: "Minakshi Singh",
    designation: "Associate Director",
  },
  // 2 - Games-distribution
  {
    key: "2",
    content:
      `"Our partnership with Freak X Games has been an amazing journey over the past five 
      years. We've been continually impressed by the quality of their HTML5 games and the fact 
      that they are available in multiple languages has helped us to boost engagement. We are 
      excited to continue growing our partnership and can't wait to see what the future holds."
      `,
    image: partner2_Img,
    name: "Minakshi Gupta",
    designation: "Associate Director",
  },
  // 3 - Jagran-media
  {
    key: "3",
    content:
      " Freak X Games offers vastly diverse HTML5 games where users can engage, enjoy, and explore a variety of levels. They rendered us some amazing HTML5 games and required customization without any delay. Hats off to their diligent team of creators who make sure all genres of games receive ample amounts of innovation and creativity.",
    image: partner3_Img,
    name: "Santraj Yadav",
    designation: "Associate Director",
  },
];

const rData_2 = [
  // 4 - jagran
  {
    key: "4",
    content_2:
      " Freak X Games offers vastly diverse HTML5 games where users can engage, enjoy, and explore a variety of levels. They rendered us some amazing HTML5 games and required customization without any delay. Hats off to their diligent team of creators who make sure all genres of games receive ample amounts of innovation and creativity.",
    image_2: partner3_Img,
    name: "Santraj Yadav",
    designation: "Associate Director",
  },
  // 5 - mx-player
  {
    key: "5",
    content_2:
      "Freak X Games develops high-quality HTML5 Games that are dynamic and engaging. They have a skilled team that is fun to work with. We are sincerely pleased with the partnership.",
    image_2: partner5_Img,
    name: "Minakshi Gupta",
    designation: "Associate Director",
  },
  //6 - APB
  {
    key: "6",
    content_2:
      " Collaboration with Freak X Games has been fruitful for us and their HTML5 Games have helped us increase our user engagement. We have so far cherished our association and look forward to more such mutually beneficial partnerships.",
    image_2: partner5_Img,
    name: "Minakshi Gupta",
    designation: "Associate Director",
  },
];

const rmData = [
  // 1 - Crazy-games  
  {
    key: "1",
    content:
      `"We have worked with Freak X Games to launch several titles on CrazyGames and everything has gone smoothly. Their games exceeded a million plays on our platform in less than a year, which is a great achievement. We look forward to more successful games and our future collaboration!"`,
    image: partner1_Img,
    name: "Minakshi Singh",
    designation: "Associate Director",
  },
  // 2 - Games-distribution
  {
    key: "2",
    content:
      `"Our partnership with Freak X Games has been an amazing journey over the past five 
      years. We've been continually impressed by the quality of their HTML5 games and the fact 
      that they are available in multiple languages has helped us to boost engagement. We are 
      excited to continue growing our partnership and can't wait to see what the future holds."
      `,
    image: partner2_Img,
    name: "Minakshi Gupta",
    designation: "Associate Director",
  },
  // 3 - Jagran-media
  {
    key: "3",
    content:
      " Freak X Games offers vastly diverse HTML5 games where users can engage, enjoy, and explore a variety of levels. They rendered us some amazing HTML5 games and required customization without any delay. Hats off to their diligent team of creators who make sure all genres of games receive ample amounts of innovation and creativity.",
    image: partner3_Img,
    name: "Santraj Yadav",
    designation: "Associate Director",
  },
  // 5 - mx-player
  {
    key: "5",
    content:
      "Freak X Games develops high-quality HTML5 Games that are dynamic and engaging. They have a skilled team that is fun to work with. We are sincerely pleased with the partnership.",
    image: partner5_Img,
    name: "Minakshi Gupta",
    designation: "Associate Director",
  },
  //6 - APB
  // {
  //   key: "6",
  //   content:
  //     " Collaboration with Freak X Games has been fruitful for us and their HTML5 Games have helped us increase our user engagement. We have so far cherished our association and look forward to more such mutually beneficial partnerships.",
  //   image: partner5_Img,
  //   name: "Minakshi Gupta",
  //   designation: "Associate Director",
  // },
];

const PageNine = () => {
  const [width, height] = useWindowSize();

  const [slideIndex, setSlideIndex] = useState(1);
  const [autoPlay, setAutoPlay] = useState(true);

  const moveDot = (index) => {
    setSlideIndex(index);
  };
  const [updateanimation, setupdateanimation] = useState(1);
  // useEffect(() => {
  //   var swiper1 = document.querySelector('.desktopAboutUsSwiper');
  //   var swiper2 = document.querySelector('.desktopAboutUsSwiper_2');

  //   // Set the initial state
  //   // if (swiper1) {
  //   swiper1.style.display = 'flex';
  //   // }
  //   // if (swiper2) {
  //   swiper2.style.display = 'none';
  //   // }

  //   // Define a function to toggle the classes
  //   function toggleSwipers() {
  //     if (swiper1.style.display === 'flex') {
  //       swiper1.style.display = 'none';
  //       swiper2.style.display = 'flex';
  //     } else {
  //       swiper1.style.display = 'flex';
  //       swiper2.style.display = 'none';
  //     }
  //   }

  //   // Set an interval to toggle the classes every 5 seconds
  //   setInterval(toggleSwipers, 4000);
  // }, [updateanimation]);


  // if (autoPlay) {
  //   setTimeout(() => {
  //     if (slideIndex === rData.length) {
  //       setSlideIndex(1);
  //     } else {
  //       setSlideIndex(slideIndex + 1);
  //     }
  //   }, 1000);
  // }




  return (
    <div className="aPageNine_bg" style={{ overflow: "hidden" }} onLoad={() => setupdateanimation(2)}>
      <div>
        <div className="aPageNine_heading">WHAT OUR CLIENTS SAY?</div>
        <div className="aPageNine_hBar_wrapper">
          <div className="aPageNine_hBar">
            <div className="aPageNine_hEl" />
          </div>
        </div>
      </div>
      {/* <div className="animDesk"> */}
          {/* --------------first row------------------ */}
          {/* <div className="desktopAboutUsSwiper">
            {rData &&
              rData.map((item, index) => {
                return (
                  <div
                    className="aPageNine_SwiperContainer"
                    style={{
                      transform:
                        index == 1 && width > 576 ? "scale(1)" : "scale(1)",
                    }}
                  >
                    <div className="card_img_client">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="cards_content">
                      {item.content}
                    </div>
                  </div>
                )
              })
            }
          </div> */}
          {/* --------------second row------------------ */}
          {/* <div className="desktopAboutUsSwiper_2" style={{ display: 'none' }}>
            {rData_2 &&
              rData_2.map((item, index) => {
                return (
                  <div
                    className="aPageNine_SwiperContainer_2"
                    style={{
                      transform:
                        index == 1 && width > 576 ? "scale(1)" : "scale(1)",
                    }}
                  >
                    <div className="card_img_client_2">
                      <img src={item.image_2} alt="" />
                    </div>
                    <div className="cards_content_2">
                      {item.content_2}
                    </div>
                  </div>
                )
              })
            }
          </div> */}
      {/* </div> */}

      <Swiper
        // data-aos="fade-up"
        slidesPerView={width > 992 ? 3 : 1}
        spaceBetween={0}
        slidesPerGroup={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        }}
        loopFillGroupWithBlank={true}
        modules={[Autoplay, Pagination]}
        pagination={{
          // enabled: false,
          clickable: true,
          bulletClass: `swiper-pagination-bullet`,
          bulletActiveClass: "swiper-pagination-bullet-active",
        }}
        className="desktopAboutUsSwiper3"
        style={{
          width: width > 1200 ? "95%" : width > 992 ? "98%" : "100%",
        }}
        centeredSlides={width > 576 ? true : true}
      >
        {rmData &&
          rmData.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                {({ isActive }) => (

                  <div
                    className="aPageNine_SwiperContainer3"
                    style={{
                      transform:
                        isActive && width > 576 ? "scale(1)" : "scale(1)",
                    }}
                  >
                    <div className="card_img_client">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="cards_content">
                      {item.content}
                    </div>
                  </div>
                )}
              </SwiperSlide>
            );
          })}
      </Swiper>


      {/* ----------------------------------------------------------------------------------------------------------------------- */}
      {/* <div className="MobileAboutUsSwiper"> */}
      <Swiper
        // data-aos="fade-up"
        slidesPerView={width > 992 ? 3 : 1}
        spaceBetween={0}
        slidesPerGroup={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        }}
        loopFillGroupWithBlank={true}
        modules={[Autoplay, Pagination]}
        pagination={{
          // enabled: false,
          clickable: true,
          bulletClass: `swiper-pagination-bullet`,
          bulletActiveClass: "swiper-pagination-bullet-active",
        }}
        className="aPageNine_mySwiper_Mobile"
        style={{
          width: width > 1200 ? "95%" : width > 992 ? "98%" : "100%",
        }}
        centeredSlides={width > 576 ? true : true}
      >
        {rmData &&
          rmData.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                {({ isActive }) => (

                  <div
                    className="aPageNine_SwiperContainer_Mobile"
                    style={{
                      transform:
                        isActive && width > 576 ? "scale(1)" : "scale(1)",
                    }}
                  >
                    <div className="card_img_client">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="cards_content">
                      {item.content}
                    </div>
                  </div>
                )}
              </SwiperSlide>
            );
          })}
      </Swiper>

      {/* </div> */}




    </div>
  );
};



export default PageNine;