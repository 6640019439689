import React from "react";

import PageOne from "../Solutions/Pages/PageOne";
import PageTwo from "../Solutions/Pages/PageTwo";
import PageThree from "../Solutions/Pages/PageThree";
import PageFour from "../Solutions/Pages/PageFour";
import PageFive from "../Solutions/Pages/PageFive";
import PageSix from "../Solutions/Pages/PageSix";
import PageSeven from "../Solutions/Pages/PageSeven";

import { HeaderSize } from "../../Constants/global";

const SolutionPage = () => {
  return (
    <div style={{ paddingTop: HeaderSize.BottomPad }}>
      <PageOne />
      <PageTwo />
      <PageThree />
      <PageFour />
      <PageFive />
      <PageSix />
      <PageSeven />
    </div>
  );
};

export default SolutionPage;
