import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./PageTwo.less";
import { Col, Row, Button } from "antd";


// Last 7
// import banner92 from "../../../Assets/Kids-E- Games-Banners/addition.webp";
// import banner91 from "../../../Assets/Banner/captain-gold.webp";
// import banner90 from "../../../Assets/Banner/The Dandy.webp";
import banner1 from "../../../Assets/Kids-E- Games-Banners/abc.webp";
import banner2 from "../../../Assets/Kids-E- Games-Banners/123.webp";
import banner3 from "../../../Assets/Kids-E- Games-Banners/spelling game.webp";
import banner4 from "../../../Assets/Kids-E- Games-Banners/matchmaking.webp";
import banner5 from "../../../Assets/Kids-E- Games-Banners/addition.webp";
import banner6 from "../../../Assets/Kids-E- Games-Banners/subtraction.webp";
import banner7 from "../../../Assets/Kids-E- Games-Banners/multiplication.webp";
import banner8 from "../../../Assets/Kids-E- Games-Banners/division.webp";
import banner9 from "../../../Assets/Kids-E- Games-Banners/picture puzzle.webp";


import banner80 from "../../../Assets/Kids-E- Games-Banners/drawing.webp";

// import banner1 from "../../../Assets/Banner/birds vs blocks.webp";

const allGames = [
  // {
  //   key: "92", // 'captain-gold.webp'
  //   gameName: "ADDITION",
  //   gameImg: banner92,
  //   gameLink: "https://test.gameskite.com/testing/vipin/KIDSKITE/ADDITION/v27/index.html",
  // },
  // {
  //   key: "91", // 'captain-gold.webp'
  //   gameName: "Captain Gold",
  //   gameImg: banner91,
  //   gameLink: "https://gameskite.com/play/captain-gold?id=portal",
  // },
  // {
  //   key: "90", // 'The Dandy.webp'
  //   gameName: "The Dandy",
  //   gameImg: banner90,
  //   gameLink: "https://gameskite.com/play/the-dandy?id=portal",
  // },

  {
    key: "1", // 'ABC Learning.webp'
    gameName: "ABC Learning",
    gameImg: banner1,
    // gameLink: "https://kids.freakxgames.com/abc-game/v4/", // game link
    gameLink: "https://www.youtube.com/watch?v=2q6xT-qBV6M&t=62s", // Medium tutorial yt link
    gameType: "AGE 2 - 4",
  },
  {
    key: "2", // 'Number Learning 123'
    gameName: "Number Learning",
    gameImg: banner2,
    // gameLink: "https://kids.freakxgames.com/123-game/v5/",
    gameLink: "https://www.youtube.com/watch?v=p0H2zogICZ4&t=1s", 
    gameType: "AGE 2 - 4",
  },
  {
    key: "3", // 'SPELLING.webp'
    gameName: "Spelling Learning",
    gameImg: banner3,
    // gameLink: "https://kids.freakxgames.com/spelling-game/v6/", 
    gameLink: "https://www.youtube.com/watch?v=nRkGdpEFfHs&t=4s", 
    gameType: "AGE 5 - 7",
  },

  {
    key: "9", // 'PICTURE PUZZLE.webp'
    gameName: "SHAPE PUZZLE",
    gameImg: banner9,
    // gameLink: "https://kids.freakxgames.com/shape-puzzle/v5/",
    gameLink: "https://www.youtube.com/watch?v=4-rwSWS4aDk&t=32s",
    gameType: "AGE 2 - 4",
  },


  {
    key: "4", // 'MATCHMAKING.webp'
    gameName: "MATCHMAKING",
    gameImg: banner4,
    // gameLink: "https://kids.freakxgames.com/match-making/v8/",
    gameLink: "https://www.youtube.com/watch?v=gQgXpOZfYVQ",
    gameType: "AGE 2 - 4",
  },
  {
    key: "5", // 'ADDITION.webp'
    gameName: "ADDITION",
    gameImg: banner5,
    // gameLink: "https://kids.freakxgames.com/addition-game/v6/",
    gameLink: "https://www.youtube.com/watch?v=HpZl5zCIuWo&t=11s",
    gameType: "AGE 5 - 7",
  },
  {
    key: "6", // 'SUBTRACTION.webp'
    gameName: "SUBTRACTION",
    gameImg: banner6,
    // gameLink: "https://kids.freakxgames.com/subtraction-game/v4/",
    gameLink: "https://www.youtube.com/watch?v=SYupdrFzgJs&t=33s",
    gameType: "AGE 5 - 7",
  },

  {
    key: "7", // 'MULTIPLICATION.webp'
    gameName: "MULTIPLICATION",
    gameImg: banner7,
    // gameLink: "https://kids.freakxgames.com/multiply-game/v5/",
    gameLink: "https://www.youtube.com/watch?v=nDAY6ROt9TY&t=19s",
    gameType: "AGE 5 - 7",
  },

  {
    key: "8", // 'division.webp'
    gameName: "division",
    gameImg: banner8,
    // gameLink: "https://kids.freakxgames.com/divide-game/v4/",
    gameLink: "https://www.youtube.com/watch?v=eJZqkkjdUwE&t=29s",
    gameType: "AGE 5 - 7",
  },

  // {
  //   key: "80", // 'Drwaing'
  //   gameName: "Drawing",
  //   gameImg: banner80,
  //   gameLink: "https://test.gameskite.com/testing/vipin/test/shap-puzzle/v1/index.html",
  // },
];

const PageTwo = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);
  const [activeIndexPosition, setActiveIndexPosition] = useState();
  const [showMore, setShowMore] = useState(false);
  const [gameDataHead, setGameDataHead] = useState([]);
  const [gameDataBody, setGameDataBody] = useState([]);
  const [gameDataBody2, setGameDataBody2] = useState([]);
  // const [selectedGameType, setSelectedGameType] = useState("All");
  const [gameType, setGameType] = useState("All");

  const handleButtonClick = (type) => {
    setGameType(type);
    filterGames(type);
  };

  const filterGames = (type) => {
    if (type === "All") {
      setGameDataHead(allGames.slice(0, 4));
      setGameDataBody(allGames.slice(4, 8));
      setGameDataBody2(allGames.slice(8));
    } else {
      const filteredGames = allGames.filter((game) => game.gameType === type);
      setGameDataHead(filteredGames.slice(0, 4));
      setGameDataBody(filteredGames.slice(4, 8));
      setGameDataBody2(filteredGames.slice(8));
    }
    setShowMore(false);
  };

  useEffect(() => {
    handleButtonClick("All");
  }, []);


  return (
    <div className="pPageTwo_Background" style={{}}>
      <Row justify="center">
        <Col className="pf_page_two_ourgames_kidsee">EDUCATIONAL <span className="pf_page_two_ourgames_kidse">KIDS GAMES</span></Col>
      </Row>
      <div
        style={{
          padding: "0 15px",
          justifyContent: "center",
          align_items: "center",
          display: "flex",
          marginTop: 5,
        }}
      >
        <div className="pf_pageTwo_hBar_InsideKidsE">
          <div className="pf_page_two_hEl_InsideKidsE" />
        </div>
      </div>
      <br />
      <div className="button-group_kidse button-group_kidse_Mobile">
        <Button
          onClick={() => handleButtonClick("All")}
          className={gameType === "All" ? "selected btn btn-primary1 group_kidse_Mobile" : "btn btn-primary1 group_kidse_MobileALL"}
        >
          ALL
        </Button>
        <Button
          onClick={() => handleButtonClick("AGE 2 - 4")}
          className={gameType === "AGE 2 - 4" ? "selected btn btn-primary1" : "btn btn-primary1"}
        >
          AGE 2 - 4
        </Button>
        <Button
          onClick={() => handleButtonClick("AGE 5 - 7")}
          className={gameType === "AGE 5 - 7" ? "selected btn btn-primary1" : "btn btn-primary1"}
        >
          AGE 5 - 7
        </Button>

      </div>


      <div className="pageTwo_AllGamesCard">
        <div className="pageTwo_HeadCardDesktop pageTwo_MobileView pageTwo_DesktopView">
          {gameDataHead &&
            gameDataHead.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild_InsideKidsE _InsideKidsE  setHeaderFont">
                      <img
                        className="bodyCardImage"
                        src={game.gameImg}
                        alt="Geeks Img"
                        width={235}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
        <div className="pageTwo_HeadCardMobile">
          {gameDataHead &&
            gameDataHead.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild_InsideKidsE ">
                      <img
                        className="bodyCardImage"
                        src={game.gameImg}
                        alt=""
                        width={235}
                      // height={"100%"}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
        <div className="pageTwo_BodyCard_kids">
          {gameDataBody &&
            gameDataBody.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild_InsideKidsE  setBodyFont_InsideKidsE">
                      <img
                        className="bodyCardImage_kidsee"
                        src={game.gameImg}
                        alt=""
                        width={270}
                      // height={"100%"}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
          {
            showMore === true
            &&
            gameDataBody2 &&
            gameDataBody2.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild_InsideKidsE setBodyFont_InsideKidsE">
                      <img
                        className="bodyCardImage_kidsee"
                        src={game.gameImg}
                        alt=""
                        width={260}
                      // height={"100%"}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // margin: "10px 15px 15px 15px",
        }}
      >
        <div
          className="pf_pageTwo_explore_more"
          onClick={() => {
            if (showMore) {
              setShowMore(false);
              window.scrollTo(0, 0); // Scroll to top when loading less
            } else {
              setShowMore(true);
              window.scrollTo(0, 1300);
            }
          }}
          style={{ display: gameDataBody2.length > 0 ? "block" : "none" }}
        >
          {showMore === true ? "LOAD LESS" : "LOAD MORE"}
        </div>


      </div>
    </div>
  );
};

export default PageTwo;
