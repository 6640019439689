import React, { useState } from "react";
import facebookBtn from "../../Assets/facebook.webp";
import linkedInBtn from "../../Assets/linkedin.webp";
import twitterBtn from "../../Assets/twitter.webp";
import instaBtn from "../../Assets/Instagram.webp";
import youtubeBtn from "../../Assets/Youtube.webp";
import { useNavigate } from "react-router-dom";

import "./Footer.less";
import { Row, Col } from "antd";
const Footer = () => {

  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  var logoWidth = 34;
  var logoHeight = 34;

  window.onload = (event) => {
    setLoading(true);
    console.log('page is fully loaded');
  };

  return (
    <div className="footer_bg">
      <div className="desktopFooter">
        <Row justify="space-between" gutter={[30, 30]}>
          <Col lg={7}>
            <div className="footer_title">LOOKING FOR HTML5 GAMES?</div>
            <div className="footer_text_1">
              Our vision is to create addictive HTML5 games to meet the entertainment needs of gaming enthusiasts while driving revenues for businesses as well.
            </div>
            <div className="footer_text_1 footer_copyright">
              @Copyright 2022. Freak X Games
            </div>
          </Col>
          <Col lg={4}>
            <div className="footer_title">QUICK LINKS</div>
            <div className="footer_text_2"
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}>Homepage</div>
            <div className="footer_text_2"
              onClick={() => {
                navigate("/our-html5-games-portfolio");
                window.scrollTo(0, 0);
              }}>Our Portfolio</div>

            <div className="footer_text_2"
              onClick={() => {
                navigate("/about-us");
                window.scrollTo(0, 0);
              }}>About Us</div>

            <div className="footer_text_2"
              onClick={() => {
                navigate("/license-html5-games");
                window.scrollTo(0, 0);
              }}>Licensing</div>
            <div className="footer_text_2"
              onClick={() => {
                navigate("/casual-gaming-solution");
                window.scrollTo(0, 0);
              }}>Revenue Share</div>
            {/* <div className="footer_text_2">Careers</div> */}

            <div className="footer_text_2"
              onClick={() => {
                window.open("https://blog.freakxgames.com/");
                window.scrollTo(0, 0);
              }}>Blog</div>

            <div className="footer_text_2"
              onClick={() => {
                // navigate("/contact-us");
                window.open('/contact-us', '_blank')
                // window.scrollTo(0, 0);
              }}>Contact Us</div>
            <div className="footer_text_2"
              onClick={() => {
                navigate("/terms-conditions");
                window.scrollTo(0, 0);
              }}>Term & Conditions</div>
          </Col>
          <Col lg={9}>
            <div className="footer_title">OFFICE ADDRESS</div>
            <div className="footer_text_3">
              Gamesfly Technologies Private limited
              <br />
              5th floor, H.B Twin Tower, Netaji Subhash Place,
              <br />
              Pitampura, Delhi 110034
              <br />
            </div>
            <div className="footer_text_3 footer_info ">hello@freakxapps.com</div>
            <div className="footer_title">FOLLOW US ON</div>
            <div>
              <a href={"https://www.linkedin.com/company/freakxapps/"} target="_blan">
                <img
                  src={linkedInBtn}
                  alt="imgBtn"
                  style={{
                    marginRight: 3,
                    cursor: "pointer",
                    width: 32,
                    height: 32,
                  }}
                />
              </a>
              <a href={"https://www.youtube.com/@Freak_X_Games"} target="_blan">
                <img
                  src={youtubeBtn}
                  alt="imgBtn"
                  style={{
                    marginRight: 3,
                    cursor: "pointer",
                    width: 32,
                    height: 32,
                  }}
                />
              </a>
              <a href={" https://www.instagram.com/freakxgames"} target="_blan">
                <img
                  src={instaBtn}
                  alt="imgBtn"
                  style={{
                    marginRight: 3,
                    cursor: "pointer",
                    width: 32,
                    height: 32,
                  }}
                />
              </a>
              <a href={"https://x.com/freakxgames"} target="_blan">
                <img
                  src={twitterBtn}
                  alt="imgBtn"
                  style={{
                    marginRight: 3,
                    cursor: "pointer",
                    width: 32,
                    height: 32,
                  }}
                />
              </a>
              <a href={"https://www.facebook.com/freakxgames"} target="_blan">
                <img
                  src={facebookBtn}
                  alt="imgBtn"
                  style={{
                    marginRight: 3,
                    cursor: "pointer",
                    width: 32,
                    height: 32,
                  }}
                />
              </a>
             
            
           
            </div>
            <div></div>
            <div></div>
          </Col>
        </Row>
      </div>

      {/* Mobile Footer */}
      <div className="mobileFooter">
        <div className="MobileFooterSection">
          <div className="MobileFooter_title">LOOKING FOR HTML5 GAMES?</div>
          <div className="MobileFooter_text_1">
            Our vision is to create addictive HTML5
            games to meet the entertainment needs of gaming enthusiasts
            while driving revenues
            for businesses as well.
          </div>
        </div>
        <div className="MobileFooterSection">
          <div className="MobileFooter_title">QUICK LINKS</div>
          <div className="MobileFooter_text_2"
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}>Homepage</div>
          <div className="MobileFooter_text_2"
            onClick={() => {
              navigate("/our-html5-games-portfolio");
              window.scrollTo(0, 0);
            }}>Our Portfolio</div>
          <div className="MobileFooter_text_2"
            onClick={() => {
              navigate("/about-us");
              window.scrollTo(0, 0);
            }}>About Us</div>
          <div className="MobileFooter_text_2"
            onClick={() => {
              navigate("/license-html5-games");
              window.scrollTo(0, 0);
            }}>Licensing</div>
          <div className="MobileFooter_text_2"
            onClick={() => {
              navigate("/casual-gaming-solution");
              window.scrollTo(0, 0);
            }}>Revenue Share</div>
          {/* <div className="MobileFooter_text_2">Careers</div> */}
          <div className="MobileFooter_text_2"
            onClick={() => {
              window.open("https://blog.freakxgames.com/");
              window.scrollTo(0, 0);
            }}>Blog</div>
          <div className="MobileFooter_text_2"
            onClick={() => {
              // navigate("/contact-us","_blan");
              window.open('/contact-us', '_blank')
              window.scrollTo(0, 0);
            }}>Contact Us</div>
          <div className="MobileFooter_text_2"
            onClick={() => {
              navigate("/terms-conditions");
              window.scrollTo(0, 0);
            }}>Term & Conditions</div>
        </div>
        <div className="MobileFooterSection">
          <div className="MobileFooter_title">OFFICE ADDRESS</div>
          <div className="MobileFooter_text_3">
            Gamesfly Technologies Private limited
            <br />
            5th floor, H.B Twin Tower, Netaji Subhash Place,
            <br />
            Pitampura, Delhi 110034
            <br />
          </div>
          <div className="MobileFooter_text_3 MobileFooter_info ">hello@freakxapps.com</div>
        </div>
        <div className="MobileFooterSection">
          <div className="MobileFooter_title">FOLLOW US ON</div>
          <div>
            <a href={"https://www.linkedin.com/company/freakxapps/"} target="_blan">
              <img
                src={linkedInBtn}
                alt="imgBtn"
                style={{
                  marginRight: 2,
                  cursor: "pointer",
                  width: 30,
                  height: 30,
                }}
              />
            </a>
            <a href={"https://www.youtube.com/@Freak_X_Games"} target="_blan">
                <img
                  src={youtubeBtn}
                  alt="imgBtn"
                  style={{
                    marginRight: 2 ,
                    cursor: "pointer",
                    width: 30,
                    height: 30,
                  }}
                />
              </a>
              <a href={" https://www.instagram.com/freakxgames"} target="_blan">
                <img
                  src={instaBtn}
                  alt="imgBtn"
                  style={{
                    marginRight: 2,
                    cursor: "pointer",
                    width: 30,
                    height: 30,
                  }}
                />
              </a>
              <a href={"https://x.com/freakxgames"} target="_blan">
              <img
                src={twitterBtn}
                alt="imgBtn"
                style={{
                  marginRight: 3,
                  cursor: "pointer",
                  width: 30,
                  height: 30,
                }}
              />
            </a>
            <a href={"https://www.facebook.com/freakxgames"} target="_blan">
              <img
                src={facebookBtn}
                alt="imgBtn"
                style={{
                  marginRight: 3,
                  cursor: "pointer",
                  width: 30,
                  height: 30,
                }}
              />
            </a>
           
          </div>
        </div>
        <div className="MobileFooter_text_1 MobileFooter_copyright">
          @Copyright 2022. Freak X Games
        </div>
      </div>
    </div>
  );
};

export default Footer;
