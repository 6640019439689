import React from "react";

import "./PageFive.less";
import { Row, Col } from "antd";

import PageSix from "./PageSix";
import PageSeven from "./PageSeven";

const PageFive = () => {
  return (
    <div className="aPageFive_bg" style={{}}>
      <div data-aos="fade-up">
        <div className="aPageFive_heading">INDUSTRIES WE SERVE</div>
        <div className="aPageFive_hBar_wrapper">
          <div className="aPageFive_hBar">
            <div className="aPageFive_hEl" />
          </div>
        </div>
      </div>

      <div className="pageFiveBody">
        <Row justify="center" data-aos="fade-up">
          <Col xs={24} sm={23} md={21} lg={18} xl={17}>
            <div className="aPageFive_text">
              Irrespective of the industry you are in, you can use our games for a
              variety of purposes. Maybe you are in search of a user engagement
              tool or looking for ways to promote your brand in a unique way. In
              either case, our games can be an ideal choice for you.
            </div>
          </Col>
        </Row>
        <Row justify="center" data-aos="fade-up">
          <Col xs={24} sm={23} md={21} lg={19} xl={17}>
            <div className="aPageFive_text">
              Our games fulfill various purposes for business, such as increasing
              brand awareness, boosting user engagement and conversion rates,
              increasing traffic on the platform, and above them all, providing an
              amazing source of entertainment.
            </div>
          </Col>
        </Row>
        <Row justify="center" data-aos="fade-up">
          <Col xs={24} sm={23} md={21} lg={19} xl={17}>
            <div className="aPageFive_text">
              With benefits like the above, we’ve successfully integrated our
              games on different platforms like eCommerce, real-money gaming apps,
              news platforms, wallet apps and many more.
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PageFive;
