import React from "react";

import "./BackDrop.less";

const BackDrop = (props) => {
  let classes = "backdrop";
  if (props.show) {
    classes = "backdrop open";
  }

  return <div className={classes} onClick={props.closeHandler} />;
};

export default BackDrop;
