import React from "react";

import "./PageOne.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";

const PageOne = () => {
  const [width, height] = useWindowSize();

  return (
    <div className="tacPageOneBg" style={{ overflow: "hidden" }}>
      <div className="tacPageOneBody">
        <div
          className="tacPageOneTitle"
          data-aos={width < 576 ? "fade-up" : "fade-up"}
        >
            TERMS AND CONDITIONS
        </div>
      </div>
    </div>
  );
};

export default PageOne;