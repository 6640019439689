import React from "react";

import "./PageOne.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";

const PageOne = () => {
  const [width, height] = useWindowSize();

  return (
    <div className="cuPageOne_bg" style={{ overflow: "hidden" }}>
      <div className="cuPageOne_row">
        <div
          className="cuPageOne_colOne"
          data-aos={width < 992 ? "fade-up" : "fade-up"}
        >
          <div className="cuPageOne_title_one">
            LET'S TALK ABOUT BUSINESS
          </div>
          <div className="cuPageOne_title_one_Mobile">
            LET'S TALK<br></br>ABOUT BUSINESS
          </div>
          <div className="cuPageOne_Italic">Just fill in the form and we will get back to you ASAP!</div>
        </div>
      </div>
    </div>
  );
};

export default PageOne;