import React from "react";
import WhyChooseUsImg1 from "../../../Assets/Why-Choose-us_1.webp";
import WhyChooseUsImg2 from "../../../Assets/Why-Choose-Us_2.webp";
import WhyChooseUsImg3 from "../../../Assets/Why-Choose-Us_3.webp";
import WhyChooseUsImg4 from "../../../Assets/Why-Choose-Us_4.webp";
import WhyChooseUsImg5 from "../../../Assets/Why-Choose-Us_5.webp";
import WhyChooseUsImg6 from "../../../Assets/Why-Choose-Us_6.webp";

import "./PageSix.less";

const PageSix = () => {
  return (
    <div style={{ }} className="pageSix_bg">
      <div className="page_six_heading" style={{ textAlign: "center" }}>
        WHY CHOOSE US ?
      </div>

      <div className="pageSix_hBar_wrapper">
        <div className="pageSix_hBar">
          <div className="page_six_hEl" />
        </div>
      </div>

      <div className="pageSix_timeline">
        <div className="pageSix_tl_container pageSix_tl_container_left">
          <div className="pageSix_card_content pageSix_card_content_left">
            <div className="pageSix_card_img1">
              <img
                src={WhyChooseUsImg1}
                alt="ImgBtn"
                className="pageSix_img1"
              />
            </div>
            <div className="pageSix_card_sNumber">1</div>
            <div style={{ textAlign: "center" }}>
              <span className="page_six_title_one">100+</span>
              <span className="page_six_title_two">HTML5 Games</span>
            </div>
            <div className="pageSix_card_text">
              Our game library consists of over 100+ engaging and top-quality
              HTML5 games and as an HTML5 game development studio, we constantly
              build more games and enable you to gain access to our rich
              collection of exclusive games.
            </div>
          </div>
        </div>
        <div className="pageSix_tl_container pageSix_tl_container_right">
          <div className="pageSix_card_content pageSix_card_content_right">
            <div className="pageSix_card_img2">
              <img
                src={WhyChooseUsImg2}
                alt="ImgBtn"
                className="pageSix_img2"
              />
            </div>
            <div className="pageSix_card_sNumber">2</div>
            <div style={{ textAlign: "center" }}>
              <span className="page_six_title_one">Localization</span>
              <span className="page_six_title_two">of Games</span>
            </div>
            <div className="pageSix_card_text">
              We offer you localized HTML5 games to deliver a delightful
              experience to your local audience. We localize our games in a way
              that helps your brand to beat linguistic barriers and connect with
              diverse audience groups in different locations effectively.
            </div>
          </div>
        </div>
        <div className="pageSix_tl_container pageSix_tl_container_left">
          <div className="pageSix_card_content pageSix_card_content_left">
            <div className="pageSix_card_img3">
              <img
                src={WhyChooseUsImg3}
                alt="ImgBtn"
                className="pageSix_img3"
              />
            </div>
            <div className="pageSix_card_sNumber">3</div>
            <div style={{ textAlign: "center" }}>
              <span className="page_six_title_one">Multiplayer/PVP</span>
              <span className="page_six_title_two">Games</span>
            </div>
            <div className="pageSix_card_text">
              As a full-fledged HTML5 game development company, we have all it
              takes to develop multiplayer and PVP games. With in-depth
              knowledge and solid hands-on experience, we offer you to embed
              more than 20+ PVP games on your platform to boost your user
              engagement.
            </div>
          </div>
        </div>
        <div className="pageSix_tl_container pageSix_tl_container_right">
          <div className="pageSix_card_content pageSix_card_content_right">
            <div className="pageSix_card_img4">
              <img
                src={WhyChooseUsImg4}
                alt="ImgBtn"
                className="pageSix_img4"
              />
            </div>
            <div className="pageSix_card_sNumber">4</div>
            <div style={{ textAlign: "center" }}>
              <span className="page_six_title_one">Premium</span>
              <span className="page_six_title_two">Content</span>
            </div>
            <div className="pageSix_card_text">
              We deliver premium quality games, combining unique and innovative
              ideas with creative designs and appealing audio-visuals. We focus
              on exceeding your expectation and providing an incredible gaming
              experience to your users.
            </div>
          </div>
        </div>
        <div className="pageSix_tl_container pageSix_tl_container_left">
          <div className="pageSix_card_content pageSix_card_content_left">
            <div className="pageSix_card_img5">
              <img
                src={WhyChooseUsImg5}
                alt="ImgBtn"
                className="pageSix_img5"
              />
            </div>
            <div className="pageSix_card_sNumber">5</div>
            <div style={{ textAlign: "center" }}>
              <span className="page_six_title_one">Provide</span>
              <span className="page_six_title_two">Score API</span>
            </div>
            <div className="pageSix_card_text">
              We allow our clients to gratify their users through our games. We
              can send you score from games and based on the scores, you can
              gratify your users on your platform. We provide multiple layers of
              encryption so that the data is highly secure and not easily
              hackable.
            </div>
          </div>
        </div>
        <div className="pageSix_tl_container pageSix_tl_container_right">
          <div className="pageSix_card_content pageSix_card_content_right">
            <div className="pageSix_card_img6">
              <img
                src={WhyChooseUsImg6}
                alt="ImgBtn"
                className="pageSix_img6"
              />
            </div>
            <div className="pageSix_card_sNumber">6</div>
            <div style={{ textAlign: "center" }}>
              <span className="page_six_title_one">End-to-End</span>
              <span className="page_six_title_two">Support</span>
            </div>
            <div className="pageSix_card_text">
We have expertise in all realms of HTML5 game development, allowing us to handle your complex projects and problems with much ease. Our team will assist you throughout, starting from the conceptualization phase to the launch of the game on your platform.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSix;
