import React from "react";

// import maleImg from "../../../Assets/male_.webp";
import aashishImg from "../../../Assets/aashish.webp";
import ranvijayImg from "../../../Assets/ranvijay.webp";
import harishImg from "../../../Assets/harish.webp";
import ashokImg from "../../../Assets/ashok.webp";
import prabhakarImg from "../../../Assets/prabhakar.webp";
import vipinImg from "../../../Assets/vipin.webp";
import sheetalImg from "../../../Assets/sheetal.webp";
import mridulaImg from "../../../Assets/mridula.webp";
import shantanuImg from "../../../Assets/shantanu.webp";
import santrajImg from "../../../Assets/santraj.webp";
import pratikshaImg from "../../../Assets/pratiksha.webp";
import saurabhImg from "../../../Assets/saurav.webp";
import deepakImg from "../../../Assets/deepak.webp";
import tavassumImg from "../../../Assets/tavassum.webp";

import "./PageEight.less";
import { Row, Col } from "antd";

const frData = [
  {
    key: "1",
    image: ranvijayImg,
    name: "Ranvijay Kumar",
    profession: "Sr. Game Artist",
  },
  {
    key: "2",
    image: harishImg,
    name: "Harish Kumar",
    profession: "Project Manager",
  },
  {
    key: "3",
    image: prabhakarImg,
    name: "Prabhakar D.",
    profession: "BD Manager",
  },
  {
    key: "6",
    image: sheetalImg,
    name: "Sheetal Suri",
    profession: "UI/UX Designer",
  },
  {
    key: "10",
    image: saurabhImg,
    name: "Saurabh Jha",
    profession: "Sr. Game Developer",
  },
  {
    key: "4",
    image: ashokImg,
    name: "Ashok Gadhvi",
    profession: "Game Developer",
  },
  {
    key: "5",
    image: vipinImg,
    name: "Vipin Yadav",
    profession: "Game Developer",
  },
  {
    key: "7",
    image: mridulaImg,
    name: "Mridula Swami",
    profession: "Graphic Designer",
  },
  {
    key: "12",
    image: shantanuImg,
    name: "Pushpaindu Nath",
    profession: "FullStack Developer",
  },
  {
    key: "9",
    image: tavassumImg,
    name: "Tavassum Khan",
    profession: "HR Manager",
  },
  // {
  //   key: "9",
  //   image: santrajImg,
  //   name: "Santraj Yadav",
  //   profession: "FullStack Developer",
  // },
  {
    key: "8",
    image: pratikshaImg,
    name: "Pratiksha Chawla",
    profession: "Digital Marketing Ex.",
  },
  {
    key: "11",
    image: deepakImg,
    name: "Deepak Choudhary",
    profession: "Game Tester",
  },
];

const srData = [
  {
    key: "1",
    image: vipinImg,
    name: "Vipin Yadav",
    profession: "Game Developer",
  },
  {
    key: "2",
    image: sheetalImg,
    name: "Sheetal Suri",
    profession: "UI/UX Designer",
  },
  {
    key: "3",
    image: mridulaImg,
    name: "Mridula Swami",
    profession: "Graphic Designer",
  },
  {
    key: "4",
    image: shantanuImg,
    name: "Pushpaindu Nath",
    profession: "FullStack Developer",
  },
  {
    key: "5",
    image: shantanuImg,
    name: "Pushpaindu Nath",
    profession: "FullStack Developer",
  },
];

const PageEight = () => {
  return (
    <div className="aPageEight_bg" style={{ overflow: "hidden" }}>
      <div>
        <div className="aPageEight_heading">OUR TEAM</div>
        <div className="aPageEight_hBar_wrapper">
          <div className="aPageEight_hBar">
            <div className="aPageEight_hEl" />
          </div>
        </div>
      </div>

      <div className="entireTeamDiv">
        <Row
          justify="center"
          gutter={[20, 70]}
          className="aPageEight_row"
          data-aos="fade-up"
        >
          <div className="aPageEight_card_lg">
            <div className="aPageEight_img_lg">
              <img
                src={aashishImg}
                alt="img"
                style={{
                  // width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div className="aPageEight_text_lg_one">Aashish Arorah</div>
            <div className="aPageEight_text_lg_two">FOUNDER & CEO</div>
          </div>
        </Row>
        <Row
          justify="space-evenly"
          gutter={[20, 70]}
          style={{ marginTop: 70 }}
          data-aos="fade-up"
        >
          {frData &&
            frData.map((item) => {
              return (
                <Col key={item.key}>
                  <div className="aPageEight_card_sm">
                    <div className="aPageEight_img_sm">
                      <img
                        src={item.image}
                        alt="img"
                        style={{
                          // width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="aPageEight_text_sm_one">{item.name}</div>
                    <div className="aPageEight_text_sm_two">
                      {item.profession.toUpperCase()}
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
      {/* <Row
        justify="space-evenly"
        style={{ marginTop: 70 }}
        gutter={[20, 70]}
        data-aos="fade-up"
      >
        {srData &&
          srData.map((item) => {
            return (
              <Col key={item.key}>
                <div className="aPageEight_card_sm">
                  <div className="aPageEight_img_sm">
                    <img
                      src={item.image}
                      alt="img"
                      style={{
                        // width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="aPageEight_text_sm_one">{item.name}</div>
                  <div className="aPageEight_text_sm_two">
                    {item.profession}
                  </div>
                </div>
              </Col>
            );
          })}
      </Row> */}
    </div>
  );
};

export default PageEight;
