import React from "react";
import aboutUsImg from "../../../Assets/about-us-section.webp";
import PageThree from "./PageThree";
import PageFour from "./PageFour";
import "./PageTwo.less";
import { Row, Col } from "antd";

import { useWindowSize } from "../../Commons/Common";

const PageTwo = () => {
  const [width, height] = useWindowSize();

  return (
    <div>
      {/* Mobile */}
      <div className="aPageTwo_bg" style={{ overflow: "hidden" }}>
        <div>
          <div className="aPageTwo_heading">
            ABOUT FREAK {width < 500 && <br />} X GAMES
          </div>
          <div className="aPageTwo_headingMobile">
            ABOUT FREAK X GAMES
          </div>
          <div className="aPageTwo_hBar_wrapper">
            <div className="aPageTwo_hBar">
              <div className="aPageTwo_hEl" />
            </div>
          </div>
        </div>
        {/* <Row justify="center" align="middle" style={{ padding: "0 15px" }}>
          <Col
            xs={8}
            sm={8}
            md={11}
            lg={10}
            xl={9}
            align="center"
          // data-aos="fade-right"
          >
            <div className="aPageTwo_img">
              <img
                src={aboutUsImg}
                alt="aboutImg"
                style={{ width: "100%", height: "100%", objectFit: "cover", float: "left" }}
              />
            </div>
          </Col>
          <Col xs={16} sm={16} md={13} lg={12} xl={10} data-aos="fade-left">
            <div className="aPageTwo_text">
              Freak X Games is a leading HTML5 game development company in India,
              dedicated to building exceptional HTML5 games by using emerging
              technologies.
            </div>
            <div className="aPageTwo_text_one">
              We are a team of highly skilled and experienced game developers &
              designers who can develop visually rich, engaging and top-quality
              HTML5 games for both mobile and web platforms that ensure an
              increased inflow of traffic with greater returns.
            </div>
            <div className="aPageTwo_text_two">
              We selected HTML5 as our game development technology, as we firmly
              believe it is a globally used and the most suitable technology for
              web and mobile game development. The cross-platform support and
              lightweight feature of HTML allow it to be used on a wide array of
              devices and ensure optimum success rates.
            </div>
          </Col>
        </Row>
        <Row
          justify="center"
          align="middle"
          data-aos="fade-right"
          style={{ padding: "0 15px" }}
        >
          <Col className="aPageTwo_Col" align="justify">
            <div className="aPageTwo_text_three">
              We are a team of highly skilled and experienced game developers &
              designers who can develop visually rich, engaging and top-quality
              HTML5 games for both mobile and web platforms that ensure an
              increased inflow of traffic with greater returns.
            </div>
          </Col>
        </Row>
        <Row
          justify="center"
          align="middle"
          data-aos="fade-left"
          style={{ padding: "0 15px" }}
        >
          <Col className="aPageTwo_Col" gutter={[0, 30]} align="justify">
            <div className="aPageTwo_text_four">
              We selected HTML5 as our game development technology, as we firmly
              believe it is a globally used and the most suitable technology for
              web and mobile game development. The cross-platform support and
              lightweight feature of HTML allow it to be used on a wide array of
              devices and ensure optimum success rates.
            </div>
          </Col>
        </Row> */}

        <div className="aboutUsBody_Mobile">
          <p>
            <img data-aos="fade-up" src={aboutUsImg} alt="" style={{ width: 135, height: 180, marginLeft: "-20px", float: "left" }} />
            <p data-aos="fade-up">Freak X Games is a leading HTML5 game development company in India,
            dedicated to building exceptional HTML5 games by using emerging
            technologies.</p>
            <p data-aos="fade-up">
            We are a team of highly skilled and experienced game developers &
            designers who can develop visually rich, engaging and top-quality
            HTML5 games for both mobile and web platforms that ensure an
            increased inflow of traffic with greater returns.</p>
            <p data-aos="fade-up">
            We selected HTML5 as our game development technology, as we firmly
            believe it is a globally used and the most suitable technology for
            web and mobile game development. The cross-platform support and
            lightweight feature of HTML allow it to be used on a wide array of
            devices and ensure optimum success rates.</p>
          </p>
        </div>
      </div>
      {/* desktop */}
      <div className="aPageTwoDesktop_bg">
        <div className="aPageTwoDesktop_Title">
          <span className="aPageTwo_heading">ABOUT FREAK X GAMES</span>
        </div>
        <div className="aPageTwo_hBar_wrapper">
          <div className="aPageTwo_hBar">
            <div className="aPageTwo_hEl" />
          </div>
        </div>
        <div className="aPageTwoDesktop_Body">
          <div className="aPageTwoDesktop_Image">
            <img
              src={aboutUsImg}
              alt="aboutImgDeskTop"
            // style={{ width: "65%"}}
            />
          </div>
          <div className="aPageTwoDesktop_Content">
            <p className="aPageTwo_textDesktop">
              Freak X Games is a leading HTML5 game development company in India,
              dedicated to building exceptional HTML5 games by using emerging
              technologies.
            </p>
            <p className="aPageTwo_textDesktop">
              We are a team of highly skilled and experienced game developers &
              designers who can develop visually rich, engaging and top-quality
              HTML5 games for both mobile and web platforms that ensure an
              increased inflow of traffic with greater returns.
            </p>
            <p className="aPageTwo_textDesktop">
              We selected HTML5 as our game development technology, as we firmly
              believe it is a globally used and the most suitable technology for
              web and mobile game development. The cross-platform support and
              lightweight feature of HTML allow it to be used on a wide array of
              devices and ensure optimum success rates.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTwo;
