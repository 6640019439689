import React from "react";
import { useNavigate } from "react-router-dom";

import "./PageNine.less";
import { Row, Col } from "antd";

const PageNine = () => {
  const navigate = useNavigate();
  
  function handleScroll() {
    const element = document.getElementById('WeAreHiring');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        // top: 0,
        // left: 0, 
        behavior: 'smooth',
      });
    }
  };
  return (
    <div className="pageNine_bg" style={{
      zIndex: 10
    }}>
      <div className="pageNine_TextDiv">
        <div className="pageNine_text_title">Ready To Join Us?</div>
        <div className="pageNine_text">
          Check out the current opening positions at Freak X Games!
        </div>
      </div>
      <div
        className="pageNine-btn"
        onClick={() => {
          window.open("/careers", "scrollY");
          window.scrollTo(0, 0);
          handleScroll();
        }}
      >
        <text className="pageNine_Btn_Text">
          SEE OPEN POSITIONS
        </text>
      </div>
    </div>
  );
};

export default PageNine;
