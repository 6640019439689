import React from "react";
import aboutMainPageImg from "../../../Assets/jungle bricks banner image 2.webp";
import "./PageOne.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";
import { useNavigate } from "react-router-dom";

const PageOne = () => {
  const [width, height] = useWindowSize();
  let navigate = useNavigate();

  function handleScroll() {
    const element = document.getElementById('WeAreHiring');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        // top: 0,
        // left: 0, 
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="carPageOne_bg">
      <div className="carPageOne_body">
        <div className="carleftSection" data-aos={width < 992 ? "fade-down" : "fade-right"}>
          <div className="carPageOne_Italic">WE ARE HIRING</div>
          <div className="carPageOne_title_one">
            <span>COME</span>
            <span className="carPageOne_title_two"> JOIN</span>
            <span> US !</span>
          </div>
          <div className="carPageOne_title_three">
          Freak X Games has diverse talent and always love seeing our team grow. We give each other opportunities to learn, grow and swim outside our comfort zones.
          </div>
          <div className="carPageOne-box">
            <div className="carPageOne-box_text"
              onClick={handleScroll}
            >SEE OPEN POSITIONS</div>
          </div>
        </div>
        <div className="carrightSection" data-aos={width < 992 ? "fade-down" : "fade-left"}>
          <img
            src={aboutMainPageImg}
            alt=""
            style={{ width: "100%", height: "99%", objectFit: "contain"}}
          />
        </div>
      </div>
    </div>
  );
};

export default PageOne;


/////////////////////////////////////

// import React from "react";
// import aboutMainPageImg from "../../../Assets/jungle bricks banner image.webp";
// import "./PageOne.less";
// import { Row, Col } from "antd";
// import { useWindowSize } from "../../Commons/Common";
// import { useNavigate } from "react-router-dom";

// const PageOne = () => {
//   const [width, height] = useWindowSize();
//   let navigate = useNavigate();

//   return (
//     <div className="crPageOne_bg">
//       <div className="crPageOne_Italic">WE ARE HIRING</div>
//       <div className="crPageOne_title_one">
//         <span>COME</span>
//         <span className="crPageOne_title_two"> JOIN</span>
//         <span> US !</span>
//       </div>
//       <div className="crPageOne_title_three">
//         Our vision is to create addictive HTML5 games to meet the
//         entertainment needs of gaming enthusiasts while driving revenue for
//         businesses as well.
//       </div>
//       <div className="crPageOne-box">
//       <div className="crPageOne-box_text"
//         onClick={() => {
//           // navigate("/contact-us");
//           window.open('/contact-us', '_blank')
//           // window.scrollTo(0, 0);
//         }}>SEE OPEN POSITIONS</div>
//       </div>
//       <div className="crPageOne_img">
//         <img
//           src={aboutMainPageImg}
//           alt="imgBtn"
//           style={{ width: "100%", height: "100%", objectFit: "cover" }}
//         />
//       </div>
//     </div>
//   );
// };

// export default PageOne;
