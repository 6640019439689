import React from "react";

import requirementsImg from "../../../Assets/requirements.webp";
import signImg from "../../../Assets/sign-initial.webp";
import provideImg from "../../../Assets/provide-UPL.webp";
import lunchImg from "../../../Assets/launch.webp";
import maintenaceImg from "../../../Assets/maintenance.webp";

import "./PageThree.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";
import { render } from "@testing-library/react";

const PageThree = () => {
  const [width, height] = useWindowSize();

  function breakDiv(number) {
    var breaks = [];
    for (var i = 0; i < number; i++) {
      breaks.push(<br></br>);
    }
    return breaks;
  }

  return (
    <div className="sPageThree_bg" style={{ overflow: "hidden" }}>
      <div className="sPageThree_Heading">REVENUE-SHARING PROCESS</div>
      <div className="sPageThree_hBar_wrapper">
        <div className="sPageThree_hBar">
          <div className="sPageThree_hEl" />
        </div>
      </div>
      <div className="sPageThree_Body">
        <Row justify="space-evenly" gutter={[10, 30]} align="middle">
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={9}
            xl={8}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="sPageThree_img">
              <img
                src={requirementsImg}
                alt="imgBtn"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={11}
            xl={10}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="sPageThree_content_wraper">
              <div className="sPageThree_step_one">STEP 01</div>
              <div className="sPageThree_subHeading_one">
                OUR REQUIREMENTS
              </div>

              <div className="sPageThree_content_one">
                Our team connects with you and provides you with a comprehensive idea of the revenue-sharing process and our requirements as well. We require at least 20k monthly traffic on your platform to allow you to add our games on a revenue sharing basis.
              </div>
            </div>
          </Col>
        </Row>
        {
          width <= 576 ?
          ( breakDiv(0) ) // Mobile
          :
          ( breakDiv(3) ) // Desk
        }
        <Row justify="space-evenly" gutter={[10, 30]} align="middle">
          <Col
            xs={{ order: 2, span: 22 }}
            sm={{ order: 2, span: 20 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 1, span: 11 }}
            xl={{ order: 1, span: 10 }}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="sPageThree_content_wraper">
              <div className="sPageThree_step_two">STEP 02</div>
              <div className="sPageThree_subHeading_two">
                SIGN THE INITIAL PUBLISHER AGREEMENT
              </div>
              <div className="sPageThree_content_two">
                Once we match the requirements, we get the publisher agreement
                signed by you to make you our partner on a revenue-sharing basis.
                By signing the agreement, you accept our terms to get the HTML5
                games on your platform and generate higher revenues.
              </div>
            </div>
          </Col>
          <Col
            xs={{ order: 1, span: 22 }}
            sm={{ order: 1, span: 20 }}
            md={{ order: 2, span: 12 }}
            lg={{ order: 2, span: 9 }}
            xl={{ order: 2, span: 8 }}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="sPageThree_img">
              <img
                src={signImg}
                alt="imgBtn"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>{" "}
          </Col>
        </Row>
        {
          width <= 576 ?
          ( breakDiv(0) ) // Mobile
          :
          ( breakDiv(3) ) // Desk
        }
        <Row justify="space-evenly" gutter={[10, 30]} align="middle">
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={9}
            xl={8}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="sPageThree_img">
              <img
                src={provideImg}
                alt="imgBtn"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={11}
            xl={10}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="sPageThree_content_wraper">
              <div className="sPageThree_step_one">STEP 03</div>
              <div className="sPageThree_subHeading_one">
                PROVIDE YOU UNIQUE <br />
                PUBLISHER LINK (IN 30 MINS)
              </div>

              <div className="sPageThree_content_one">
                After you signed the publisher agreement, our team sends you a unique publisher link with a unique publisher ID that contains 100+ addictive HTML5 games. Our simple integration approach allows us to provide you link within 30 mins.
              </div>
            </div>
          </Col>
        </Row>
        {
          width <= 576 ?
          ( breakDiv(0) ) // Mobile
          :
          ( breakDiv(3) ) // Desk
        }
        <Row justify="space-evenly" gutter={[10, 30]} align="middle">
          <Col
            xs={{ order: 2, span: 22 }}
            sm={{ order: 2, span: 20 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 1, span: 11 }}
            xl={{ order: 1, span: 10 }}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="sPageThree_content_wraper">
              <div className="sPageThree_step_two">STEP 04</div>
              <div className="sPageThree_subHeading_two">LAUNCH</div>
              <div className="sPageThree_content_two">
                Once you get the unique publisher link, you can integrate it onto
                your platform. Our team helps you to ensure a faster launch of the
                addictive HTML5 games on your platform. We enable you to overcome
                the potential challenges of the launch process and generate more
                revenue.
              </div>
            </div>
          </Col>
          <Col
            xs={{ order: 1, span: 22 }}
            sm={{ order: 1, span: 20 }}
            md={{ order: 2, span: 12 }}
            lg={{ order: 2, span: 9 }}
            xl={{ order: 2, span: 8 }}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="sPageThree_img">
              <img
                src={lunchImg}
                alt="imgBtn"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>{" "}
          </Col>
        </Row>
        {
          width <= 576 ?
          ( breakDiv(0) ) // Mobile
          :
          ( breakDiv(3) ) // Desk
        }
        <Row justify="space-evenly" gutter={[10, 30]} align="middle">
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={9}
            xl={8}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="sPageThree_img">
              <img
                src={maintenaceImg}
                alt="imgBtn"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={11}
            xl={10}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="sPageThree_content_wraper">
              <div className="sPageThree_step_one">STEP 05</div>
              <div className="sPageThree_subHeading_one">
                SUPPORT <br />
                AND MAINTENANCE
              </div>

              <div className="sPageThree_content_one">
                We continue to provide you with quality support to ensure your
                players enjoy an uninterrupted gaming experience and on the other hand, you make good revenues as well. Our team is readily
                available to fix all the issues and deliver delightful experiences
                to your users.
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PageThree;
