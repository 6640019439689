import React from "react";
import { useLocation } from 'react-router-dom';

import "./PageTwo.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../../Commons/Common";

import ApplyForm from "../../../Commons/ApplyForm";
import ApplyForm1 from "../../../Commons/ApplyForm1";

// import ReactDOM from 'react-dom';
import Modal from 'react-modal';

function CloseButton({ onClick }) {
  return (
    <button type="button" className="ApplyForm-close-button" onClick={onClick}>
      &times;
    </button>
  );
}

const customStyles = {
  content: {
    top: '57%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -52%)',
    padding: '0px',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
  },
  overlay: {
    zIndex: '8',
    backgroundColor: 'rgba(0, 0, 0, 0.50)'
  }
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');
Modal.setAppElement('#root')

const PageTwo = () => {
  const [width, height] = useWindowSize();
  const { state } = useLocation();

  ////////////--Modal-Start--////////////

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  ////////////--Modal-End--////////////

  // console.log(state.post_roleList);

  return (
    <div className="jdPageTwo_bg" style={{ overflow: "hidden" }}>
      <div className="jdPageTwo_body">
        <div className="jdPageTwo_left">
          <div className="leftTitle">
            Roles & Responsibilities -
          </div>
          <ul className="roleListUL">
            {state.post_roleList.map((item, i) => {
              return (
                <div key={item.key}>
                  <li><div className="roleListLI">{item}</div></li>
                </div>
              )
            })}
          </ul>
          <div className="leftTitle">
            Skills & Qualifications -
          </div>
          <ul className="roleListUL">
            {state.post_skillsList.map((item, i) => {
              return (
                <div key={item.key}>
                  <li><div className="roleListLI">{item}</div></li>
                </div>
              )
            })}
          </ul>
          <div className="leftTitle">
            Perks -
          </div>
          <ul className="roleListUL">
            {state.post_perksList.map((item, i) => {
              return (
                <div key={item.key}>
                  <li><div className="roleListLI">{item}</div></li>
                </div>
              )
            })}
          </ul>
          <div className="applyButtonJD">
            <div className="applyButtonJDText"
              onClick={() => {
                openModal();
              }}>
              APPLY NOW
            </div>
          </div>
        </div>
        <div className="jdPageTwo_center">
        </div>
        <div className="jdPageTwo_right">
          <div className="rightTitle">
            Experience
          </div>
          <div className="rightTitle2">
            {state.post_experience}
          </div>
          <div className="rightTitle">
            Work Type
          </div>
          <div className="rightTitle2">
            {state.post_type}
          </div>
          <div className="rightTitle">
            Openings
          </div>
          <div className="rightTitle2">
            {state.post_openings}
          </div>
          <div className="rightTitle">
            Location
          </div>
          <div className="rightTitle2">
            Delhi
          </div>
          <br />
          <div className="applyButtonJD2">
            <div className="applyButtonJD2Text"
              onClick={() => {
                openModal();
              }}>
              APPLY NOW
            </div>
          </div>
        </div>
      </div>
      <div className="jdPageTwo_mobileBody">
        <div className="upperJd">
          <div className="upperRow">
            <div className="upperColumn1">
              <div className="upperTitle">
                EXPERIENCE
              </div>
              <div className="upperContent">
                {state.post_experience}
              </div>
            </div>
            <div className="upperColumn2">
              <div className="upperTitle">
                OPENINGS
              </div>
              <div className="upperContent">
                {state.post_openings}
              </div>
            </div>
          </div>
          <div className="upperRow">
            <div className="upperColumn1">
              <div className="upperTitle">
                WORK TYPE
              </div>
              <div className="upperContent">
                {state.post_type}
              </div>
            </div>
            <div className="upperColumn2">
              <div className="upperTitle">
                LOCATION
              </div>
              <div className="upperContent">
                DELHI
              </div>
            </div>
          </div>
        </div>
        <div className="lowerJd">
          <div className="lowerTitle">
            Roles & Responsibilities -
          </div>
          <ul className="lowerListUL">
            {state.post_roleList.map((item, i) => {
              return (
                <div key={item.key}>
                  <li><div className="lowerListLI">{item}</div></li>
                </div>
              )
            })}
          </ul>
          <div className="lowerTitle">
            Skills & Qualifications -
          </div>
          <ul className="lowerListUL">
            {state.post_skillsList.map((item, i) => {
              return (
                <div key={item.key}>
                  <li><div className="lowerListLI">{item}</div></li>
                </div>
              )
            })}
          </ul>
          <div className="lowerTitle">
            Perks -
          </div>
          <ul className="lowerListUL">
            {state.post_perksList.map((item, i) => {
              return (
                <div key={item.key}>
                  <li><div className="lowerListLI">{item}</div></li>
                </div>
              )
            })}
          </ul>
          <div className="applyButtonJD">
            <div className="applyButtonJDText"
              onClick={() => {
                openModal();
              }}>
              APPLY NOW
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div className="careerPageModal">
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {width <= 576 ?
            <ApplyForm1
              className="applyForm_bg_ApplyForm"
              title="Fill in Required Details"
              textAlign="left"
              buttonText="Submit"
              buttonTextMobile="Submit"
              textDecoration="Underline"
            >
              <CloseButton onClick={() => setIsOpen(false)} />
            </ApplyForm1>
            :
            <ApplyForm
              className="applyForm_bg_ApplyForm"
              title="Fill in Required Details"
              textAlign="left"
              buttonText="Submit"
              buttonTextMobile="Submit"
              textDecoration="Underline"
            >
              <CloseButton onClick={() => setIsOpen(false)} />
            </ApplyForm>
          }
        </Modal>
      </div>
    </div>
  );
};

export default PageTwo;