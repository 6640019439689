import React from "react";

import "./PageTwo.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";

const PageTwo = () => {
  const [width, height] = useWindowSize();

  return (
    <div className="tacPageTwoBg" style={{ overflow: "hidden" }}>
      <div className="tacPageTwoBody">
        {/* 1 */}
        <br />
        <div className="tacPageTwoSubTitle">
          Introduction
        </div>
        <div className="tacPageTwoContent">
          <p>
            By using freakxgames.com and the freakxgames.com website (Service), a service of Gamesfly Technologies Private Limited, you are agreeing to be bound to the following terms and conditions (Terms and Conditions).
          </p>
          <p>
            Freakxgames.com reserves the right to update and change these Terms and Conditions at its discretion without notice. Any new features in excess to the current Service shall be subject to these Terms and Conditions.
          </p>
          <p>
            Continued use of the Service after any such changes shall constitute your consent to such changes. You can review the most current version of the Terms and Conditions at any time at: freakxgames.com/terms-conditions.
          </p>
          <p>
            By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service. Violation of any of the terms below may result in immediate termination of your Service account.
          </p>
          <p>
            Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. You warrant that you are at least 18-years-old and you are legally capable of entering into binding contracts. If you are under 18-years-old, you warrant that you have obtained consent from your parent or guardian and they agree to be bound by these Terms on your behalf.
          </p>
        </div>
        {/* 2 */}
        <div className="tacPageTwoSubTitle">
          Illegal content violation
        </div>
        <div className="tacPageTwoContent">
          <p>
            Service does not allow content that is illegal, promotes illegal activity or infringes on the legal rights of others.
          </p>
          <p>
            Intellectual property violation
          </p>
          <p>
            Service does not allow content that infringes copyright; sells or promotes the sale of counterfeit products; counterfeit goods contain a trademark or logo that is identical to or substantially indistinguishable from the trademark of another; they mimic the brand features of the product in an attempt to pass themselves off as a genuine product of the brand owner.
          </p>
        </div>
        {/* 3 */}
        <div className="tacPageTwoSubTitle">
          Endangered or threatened species violation
        </div>
        <div className="tacPageTwoContent">
          <p>
            Service does not allow content that promotes the sale of products obtained from endangered or threatened species.
          </p>
        </div>
        {/* 4 */}
        <div className="tacPageTwoSubTitle">
          Dangerous or derogatory content violation
        </div>
        <div className="tacPageTwoContent">
          <p>
            Service does not allow content that incites hatred against, promotes discrimination of or disparages an individual or group on the basis of their race or ethnic origin, religion, disability, age, nationality, veteran status, sexual orientation, gender, gender identity or other characteristic that is associated with systemic discrimination or marginalisation. Service does not allow content that harasses, intimidates or bullies an individual or group of individuals. Service does not allow content that threatens or advocates physical or mental harm to oneself or others. Service does not allow content that relates to a current, major health crisis and contradicts authoritative, scientific consensus. Service does not allow content that exploits others through extortion.
          </p>
        </div>
        {/* 5 */}
        <div className="tacPageTwoSubTitle">
          Enabling dishonest behaviour violation
        </div>
        <div className="tacPageTwoContent">
          <p>
            Service does not allow content that promotes any form of hacking or cracking and/or provides users with instructions, equipment or software that tampers with or provides unauthorised access to devices, software, servers or websites. Service does not allow content that enables a user, or promotes products and services that enable a user, to track or monitor another person or their activities without their authorisation. This does not include private investigation services; products or services designed for parents to track or monitor their underage children.
          </p>
        </div>
        {/* 6 */}
        <div className="tacPageTwoSubTitle">
          Misrepresentative content violation
        </div>
        <div className="tacPageTwoContent">
          <p>
            Service does not allow content that misrepresents, misstates or conceals information about you, your content or the primary purpose of your web destination. Service does not allow content that entices users to engage with content under false or unclear pretences; engages in 'phishing' for users’ information; promotes content, products or services using false, dishonest or deceptive claims; makes claims that are demonstrably false and could significantly undermine participation or trust in an electoral or democratic process; falsely implies having an affiliation with, or endorsement by, another individual, organisation, product or service; deceives users through manipulated media related to politics, social issues or matters of public concern.
          </p>
        </div>
        {/* 7 */}
        <div className="tacPageTwoSubTitle">
          Malicious or unwanted software violation
        </div>
        <div className="tacPageTwoContent">
          <p>
            Service does not allow content that contains malicious software or 'malware' that may harm or gain unauthorised access to a computer, device or network.
          </p>
        </div>
        {/* 8 */}
        <div className="tacPageTwoSubTitle">
          Sexually explicit content violation
        </div>
        <div className="tacPageTwoContent">
          <p>
            Service does not allow content that includes graphic sexual text, image, audio, video.; contains non-consensual sexual themes, whether simulated or real. Service does not allow content that: may be interpreted as promoting a sexual act in exchange for compensation.
          </p>
        </div>
        {/* 9 */}
        <div className="tacPageTwoSubTitle">
          Child sexual abuse and exploitation violation
        </div>
        <div className="tacPageTwoContent">
          <p>
            Service does not allow content that sexually exploits or abuses children, or content that promotes the sexual exploitation or abuse of children; endangers children.
          </p>
        </div>
        {/* 10 */}
        <div className="tacPageTwoSubTitle">
          Intellectual Property
        </div>
        <div className="tacPageTwoContent">
          <p>
            The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Gamesfly Technologies Private Limited and its licensors. The Service is protected by copyright, trademark, and other laws of india. You may not duplicate, copy, or reuse any portion of the code (HTML/CSS/JavaScript) or visual design elements. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Gamesfly Technologies Private Limited. Nothing in these Terms constitutes a transfer of any Intellectual Property rights from us to you.
          </p>
          <p>
            You are permitted to use the Service only as authorised by us. As a user, you are granted a limited, non-exclusive, revocable, non-transferable right to use the Service to create, display, use, play, and download Content subject to these Terms.
          </p>
          <p>
            Our Intellectual Property must not be used in connection with a product or service that is not affiliated with us or in any way brings us in disrepute.
          </p>
          <p>
            You must not modify the physical or digital copies of any Content you print off or download in any way, and you must not use any illustrations, photographs, video or audio, or any graphics separately from any accompanying text.
          </p>
          <p>
            Any opinions, advice, statements, services, offers, or other information or content expressed or made available by any other users are those of the respective authors or distributors and not of us.
          </p>
        </div>
        {/* 11 */}
        <div className="tacPageTwoSubTitle">
          Modifications to the Service
        </div>
        <div className="tacPageTwoContent">
          <p>
            Freakxgames.com reserves the right at any time, with or without notice, to modify or discontinue the Service, temporarily or permanently.
          </p>
          <p>
            It is your sole responsibility to periodically check these Terms for any changes. If you do not agree with any of the changes to these Terms, it is your sole responsibility to stop using the Service. Your continued use of the Service will be deemed as your acceptance thereof.
          </p>
        </div>
        {/* 12 */}
        <div className="tacPageTwoSubTitle">
          Limitation of Liability
        </div>
        <div className="tacPageTwoContent">
          <p>
            To the fullest extent permitted by law, freakxgames.com excludes all terms, conditions, warranties, and guarantees which might be implied into these terms and conditions.
          </p>
          <p>
            Freakxgames.com’s liability to you in respect of any claim made by you arising out of or in connection to the Service is limited, at the option of freakxgames.com, to the provision of the Service again or paying to provide the Service again.
          </p>
          <p>
            You expressly understand and agree that freakxgames.com shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses , resulting from: (i) the use or the inability to use the service; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to the service.
          </p>
          <p>
            You have sole responsibility for adequate security protection and backup of data and/or equipment used in connection with your usage of the Service and will not make a claim against for lost data, re-run time, inaccurate instruction, work delays or lost profits resulting from the use of the Service. You must not assign or otherwise dispose of your account to any other person.
          </p>
          <p>
            Without limiting the foregoing, in no event will our aggregate liability to you exceed, in total, the amounts paid by you to us.
          </p>
        </div>
        {/* 13 */}
        <div className="tacPageTwoSubTitle">
          Indemnification
        </div>
        <div className="tacPageTwoContent">
          <p>
            As a condition of your access to and use of the Service, you agree to indemnify us and our successors and assigns for all damages, costs, expenses and other liabilities, including but not limited to legal fees and expenses, relating to any claim arising out of or related to your access to and use of the Service or your breach of these Terms and any applicable law or the rights of another person or party.
          </p>
          <p>
            This indemnification section survives the expiration of your registration, and applies to claims arising both before and after the registration ends.
          </p>
          <p>
            Applicable Law and Jurisdiction{<br></br>}
            These terms and conditions are governed by and construed in accordance with the laws applicable in New Delhi, India.
          </p>
          <p>
            You and freakxgames.com agree to submit to the exclusive jurisdiction of the courts of New Delhi, India.
          </p>
          <p>
            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
          </p>
        </div>
        {/* 14 */}
        <div className="tacPageTwoSubTitle">
          Links To Other Websites
        </div>
        <div className="tacPageTwoContent">
          <p>
            Our Service may contain links to third-party web sites or services that are not owned or controlled by Gamesfly Technologies Private Limited.
          </p>
          <p>
            Gamesfly Technologies Private Limited has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Gamesfly Technologies Private Limited shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
          </p>
          <p>
            We only provide links to external websites as a convenience, and the inclusion of such a link to external websites do not imply our endorsement of those websites. You acknowledge and agree that when you access other websites on the Internet, you do so at your own risk.
          </p>
          <p>
            We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
          </p>
          <p>
            This site may link to other social networks or sitesand these networks make collect cookies. These cookies are used by these sites for analytical purposes and will often be tailored to the interest of the user. We recommend that you read the privacy policies of any of these websites. freakxgames.com is not responsible for any information that is collected by these websites. To learn more about cookies and how other websites collect them, please visit www.allaboutcookies.org. Enable show you how you can disable or deactivate cookie collection through various websites.
          </p>
        </div>
        {/* 15 */}
        <div className="tacPageTwoSubTitle">
          Customer Data
        </div>
        <div className="tacPageTwoContent">
          <p>
            The Customer owns the rights to its data as data controller, and the service acts as data processor on the Customer's behalf. All processing by the service of the personal data and other data provided by the Customer shall be in accordance with the applicable laws. The service's processing of personal data on behalf of the Customer shall therefore only be done in order to provide the Product and shall be subject to the Customer's written instructions.
          </p>
          <p>
            The Customer is obligated to keep user logins and passwords to the Product secret from any unauthorized users or third parties. The Customer is obligated to ensure that the personal data provided by the Customer and used in the Product is processed by the Customer in accordance with all applicable laws. The Customer is obligated to ensure that the Customer's data provided in the Product, including personal data, do not violate any third party intellectual property rights and/or any applicable legislation.
          </p>
          <p>
            The service is entitled to delete any data that in the sole discretion of the service constitutes a breach of the aforesaid undertaking by the Customer, and the Customer will not be entitled to any compensation in that respect.
          </p>
        </div>
        {/* 16 */}
        <div className="tacPageTwoSubTitle">
          Third Party Cookies
        </div>
        <div className="tacPageTwoContent">
          <p>
            This website may use various analytical programs such as Google Analytics which is a web analysis program through Google Inc., The analytics program used by Google contains cookies which will be stored on your computer and analyze web usage. The information that is generated by these cookies on your web usage when you are on this website, will be transmitted and then stored on a Google server which is located in the United State when the IP address becomes activated through your interaction with this website. Your IP address can be shortened if you’re a member of the European Union or another state which has ratified the European Economic Area agreement. Only in a very rare exceptional case will your IP address be sent toa server operated by Google whichis located in United States and then it shortened at that location. The webmaster of freakxgames.com states that Google will use the information to look at your usage of that information. It will then compile reports on the activities of the website and then provide some other services related to the use of the internet and that of an Internet operator. The IP address that is transmitted by the browser you use through the Google analytics program is not associated with other data that is collected by Google. if you wish to prevent the installation of any cookies that your browser collects,please be aware that you may not be able to use all the features found on this website. If you wish to prevent Google from using Google Analytics, you can visit the Google Website and make changes via the Google Analytics Opt-out page.
          </p>
          <p>
            Please refer to the following links for the complete Google and AdSense policy guidelines if you will be monetizing your content with Google AdSense.
            <br />
            <br />
            <a href='https://support.google.com/adsense/answer/48182' target="_blan" className="hyperLink">https://support.google.com/adsense/answer/48182</a>
            <br />
            <br />
            <a href='https://support.google.com/adsense/answer/10502938' target="_blan" className="hyperLink">https://support.google.com/adsense/answer/10502938</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageTwo;