import React from "react";
import { useNavigate } from "react-router-dom";

import "./PageSeven.less";

const PageSeven = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="pageSeven_bg">
        <div className="pageSeven_text">LET❜S WORK TOGETHER !</div>
        <div className="pageSeven_button" onClick={() => {
          // navigate("/contact-us");
          window.open('/contact-us', '_blank')
          // window.scrollTo(0, 0);
        }}>CONTACT US</div>
      </div>
    </div>
  );
};

export default PageSeven;
