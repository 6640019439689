/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";

import "./ApplyForm.less";

import { Form, Input, Button, Select } from "antd";
import { useWindowSize } from "./Common";
// import { getValue } from "@testing-library/user-event/dist/utils";
// import emailjs from '@emailjs/browser';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const ApplyForm = (props) => {

  const [captcha, setCaptcha] = useState(null);
  const captchaRef = useRef();

  function onChange(value) {
    console.log("Captcha value:", value);
    setCaptcha(value);
  }

  const [width, height] = useWindowSize();
  const [form] = Form.useForm();
  //   const nameValue = Form.useWatch("name", form);

  const [selectedJob, setSelectedJob] = useState("")

  const sendEmail2 = (e) => {

    // document.getElementById("formApplyForInput").value = selectedJob
    console.log("First Name:", document.getElementById('formFirstName').value); // 1
    console.log("Last Name:", document.getElementById('formLastName').value); // 2
    console.log("Email:", document.getElementById('formEmail').value); // 3
    console.log("Number:", document.getElementById('formNumber').value); // 4
    console.log("Message:", document.getElementById('formMessage').value); // 5
    console.log("Applied For:", selectedJob); // 6
    // console.log("Resume:", document.getElementById('formResume').value); // 7

    var firstName = String(document.getElementById('formFirstName').value);
    var lastName = String(document.getElementById('formLastName').value);
    var email = String(document.getElementById('formEmail').value);
    var number = String(document.getElementById('formNumber').value);
    var message = String(document.getElementById('formMessage').value);
    var applyFor = selectedJob;
    e.preventDefault();

    var button1 = document.getElementById("submitButton2");
    var text1 = document.getElementById("buttonText2");
    button1.disabled = true;

    // console.log("firstName: ", firstName.length);
    // console.log("lastName: ", lastName.length);
    // console.log("email: ", email.length);
    // console.log("number: ", number.length);
    // console.log("message: ", message.length);
    // console.log("applyFor: ", applyFor.length);
    // console.log("resume: ", resume.length);
    console.log("Form Data Career:\n", firstName, "\n", lastName, "\n", email, "\n", number, "\n", applyFor);

    if (firstName.length > 0 && email.length > 0 && message.length > 0 && applyFor.length > 0 && (captcha !== null && captcha !== "null")) {
      console.log("IF");
      button1.style.backgroundColor = "#4e7884";
      text1.textContent = "Sending...";
      // button1.style.backgroundColor = "white";
      let data = JSON.stringify({
        "from": {
          "address": "noreply@freakxgames.com"
        },
        "to": [
          {
            "email_address": {
              "address": "hello@freakxapps.com",
              "name": "Aashish arorah"
            }
          }
        ],
        "subject": `Freak X Apps -- Job Application`,
        "htmlbody": `<div><h3>Hello Freak X Games,</h3><br/><ul><li>Name: ${firstName} ${lastName}</li><li>Email: ${email}</li><li>Phone: ${number}</li><li>Applied For: ${applyFor}</li><li>Message: ${message}</li></ul></div>`
      });

      let config = {
        method: 'post',
        url: 'https://cors.gameskite.com/https://api.zeptomail.in/v1.1/email',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Zoho-enczapikey PHtE6r1cF7y+32B9pkRVtqS+Ec+gZIgr+OlhKwhFt4hLW/dVSk1c/9wqxzKx/00iUfgTQv/IzN1g4LnP4bmEdmjlYGwdW2qyqK3sx/VYSPOZsbq6x00cslQdc03bVIXmddJu3SXWutjfNA==',
        },
        data: data
      };

      axios.request(config)
        // emailjs.sendForm('vsfvdsv', 'sdvsdv', document.getElementById('myForm'), 'dsvsdv')
        .then((result) => {
          // console.log(result.text);
          console.log(JSON.stringify(result.data));
          e.target.reset();
          text1.textContent = `Thanks for your message.`;
          setTimeout(() => {
            button1.disabled = false;
            text1.textContent = "Will get back to you!";
          }, 2000);
          setTimeout(() => {
            button1.style.backgroundColor = "#21ce86";
            button1.disabled = false;
            text1.textContent = width > 576 ? props.buttonText : props.buttonTextMobile;
            window.location.reload();
          }, 4000);
          // alert("Email Sent!")
        })
        .catch((error) => {
          text1.textContent = "Technical issue!!! Try later.";
          console.log(error);
          e.target.reset();
          button1.style.backgroundColor = "#4e7884";
          setTimeout(() => {
            button1.style.backgroundColor = "#21ce86";
            button1.disabled = false;
            text1.textContent = width > 576 ? props.buttonText : props.buttonTextMobile;
            window.location.reload();
          }, 2000);
        });
    }
    else {
      console.log("ELSE");
      if (firstName.length > 0 && email.length > 0 && message.length > 0 && applyFor.length > 0) {
        if (captcha == null || captcha == "" || captcha == "null") {
          text1.textContent = "Please fill the captcha";
        }
      }
      else {
        text1.textContent = "Please fill the required details";
      }
      // text1.textContent = "Please fill the required details";
      button1.style.backgroundColor = "#4e7884";
      setTimeout(() => {
        button1.style.backgroundColor = "#21ce86";
        button1.disabled = false;
        text1.textContent = width > 576 ? props.buttonText : props.buttonTextMobile;
      }, 2500);
    }
  };

  return (
    <div className={width < 576 ? "className" : props.className}>
      {props.children}
      <div className="applyForm_heading" style={{
        textAlign: props.textAlign, textDecoration: "#21ce86", color: "#4e7884"
      }}>
        {width > 576 ? props.title : ""}
      </div>
      <Form
        id="myForm"
        form={form}
        onSubmitCapture={sendEmail2}
        layout="vertical"
        autoComplete="on"
        style={{
          fontSize: 16,
          fontWeight: 600,
          textAlign: "start",
          color: "#6c6d6c",
        }}
      >
        <div style={{ display: "flex" }}>
          <Form.Item
            name="firstName"
            label={<label style={{ color: "#404140" }}>First Name</label>}
            style={{ marginBottom: 15, color: "#6c6d6c", marginRight: "10px" }}
            rules={[
              {
                required: true,
                message: 'Please enter first name!',
              },
            ]}
          >
            <Input
              name="firstName"
              id="formFirstName"
              // placeholder="Enter first name"
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: "#6c6d6c"
              }}
              rules={[
                {
                  required: true,
                  message: 'Please enter your name!',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={<label style={{ color: "#404140" }}>Last Name</label>}
            style={{ marginBottom: 15, color: "#6c6d6c" }}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input
              name="lastName"
              id="formLastName"
              // placeholder="Enter last name"
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: "#6c6d6c",
              }}
              rules={[
                {
                  required: false,
                  message: 'Please enter last name!',
                },
              ]}
            />
          </Form.Item>
        </div>

        <div style={{ display: "flex" }}>
          <Form.Item
            style={{ marginBottom: 15, marginRight: "10px" }}
            name="email"
            label={<label style={{ color: "#404140" }}>Email Address</label>}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              name="email"
              id="formEmail"
              // placeholder="Enter email address"
              style={{ fontSize: 16, fontWeight: 600, color: "#6c6d6c" }}
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 15 }}
            name="phone"
            label={<label style={{ color: "#404140" }}>Mobile Number</label>}
          >
            <Input
              name="phone"
              id="formNumber"
              // placeholder="Enter phone number"
              style={{ fontSize: 16, fontWeight: 600, color: "#6c6d6c" }}
            />
          </Form.Item>
        </div>

        <div style={{}}>
          <Form.Item
            style={{ marginBottom: 15, marginRight: "10px", width: "100%" }}
            name="applyFor"
            label={<label style={{ color: "#404140" }}>Apply For</label>}
            rules={[
              {
                required: true,
                message: `Apply for is required `
              },
            ]}
          >
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              name="applyForSelect"
              id="formApplyFor"
              onChange={value => { setSelectedJob(value) }}
              value={selectedJob} // Ensure the value is controlled
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: "#6c6d6c",
                width: "100%"
              }}
              className="formSelect"
              defaultValue={{ key: "Choose any option" }}
              bordered={false}
            >
              <Select.Option className="ApplyForm_selectOptions" key={"Business Development Executive"} value="Business Development Executive" >Business Development Executive</Select.Option>
            </Select>
          </Form.Item>



          {/* <Form.Item
            style={{ marginBottom: 15, marginRight: "10px", width: "100%" }}
            name="applyFor"
            label={<label style={{ color: "#404140" }}>Apply For</label>}
            rules={[
              {
                required: true,
                message: `Apply for is required `
              },
            ]}
          >
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              name="applyForSelect"
              id="formApplyFor"
              onChange={value => { setSelectedJob(value) }}
              value={selectedJob} // Ensure the value is controlled
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: "#6c6d6c",
                width: "100%"
              }}
              className="formSelect"
              defaultValue={{ key: "Choose any option" }}
              bordered={false}
            >
              <Select.Option className="ApplyForm_selectOptions" key={"Business Development Executive"} value="Business Development Executive" >Business Development Executive</Select.Option>
            </Select>
          </Form.Item> */}

          {/* <Form.Item
            style={{ marginBottom: 15 }}
            name="resume"
            label="Upload Resume"
          >
            <Input
              name="resume"
              id="formResume"
              // placeholder="Upload your resume"
              style={{ fontSize: 16, fontWeight: 600, color: "#6c6d6c" }}
              type="file"
              disabled
            />
          </Form.Item> */}
        </div>

        <Form.Item
          style={{ marginBottom: 25 }}
          name="message"
          label={<label style={{ color: "#404140" }}>Message</label>}
          rules={[
            {
              required: true,
              message: "Please input your message",
            },
          ]}
        >
          <Input.TextArea
            name="message"
            id="formMessage"
            rows={3}
            // placeholder="Type your message"
            style={{ fontSize: 16, fontWeight: 600, color: "#6c6d6c" }}
          />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 25, zIndex: 99999, position: 'relative' }}
        >
          <ReCAPTCHA
            sitekey="6LeqDQwqAAAAAABsmlwj8QHZGe5WhttTWEG4_bhA"
            onChange={onChange}
          // ref={captchaRef}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 10 }}>
          <Button className="formButton" id="submitButton2" type="primary" htmlType="submit">
            <div className="formButton_text" id="buttonText2">
              {width > 576 ? props.buttonText : props.buttonTextMobile}
            </div>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ApplyForm;
