import React from "react";

import Collapse from "../../Commons/Collapse";

import "./PageSix.less";
import { Row, Col } from "antd";

const cData = [
  {
    key: "4",
    question: "What is the timeline for the integration of the games?",
    answer1:
      "The integration timeline of the games depends on the client’s requirements.",
  },
  {
    key: "2",
    question: "Do we provide source code and HTML5 files for the games?",
    answer1:
      "No, we only provide game links to our clients.",
  },
  {
    key: "3",
    question: "Do we integrate the client’s SDK into our games?",
    answer1:
      "Based on the client’s requirements, we do integrate the client’s SDK into our games.",
  },
  {
    key: "1",
    question: "What type of data points do we provide in Licensing?",
    answer1:
      `In the licensing model, we can send you the following data points for any game - game time, user score, best score, level complete, etc.`,
  },
  {
    key: "5",
    question: "Do we provide support and maintenance services?",
    answer1:
      "Yes, we offer you continuous support even after the game is launched on your platform.",
  },
];

const PageSix = () => {
  return (
    <div className="lPageSix_bg">
      <div className="lPageSix_Heading">FREQUENTLY ASKED QUESTIONS</div>
      <div className="lPageSix_hBar_wrapper">
        <div className="lPageSix_hBar">
          <div className="lPageSix_hEl" />
        </div>
      </div>
      <div className="pageSix_questionCardDiv">
        <Row justify="center" data-aos="fade-up">
          <Col xs={24} sm={22} md={21} lg={20} xl={18}>
            <Collapse data={cData} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PageSix;
