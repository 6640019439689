import React from "react";

import PageOne from "./Pages/PageOne";
import PageTwo from "./Pages/PageTwo";
import PageThree from "./Pages/PageThree";
// import PageFour from "./Pages/PageFour";
// import PageFive from "./Pages/PageFive";
// import PageSix from "./Pages/PageSix";
// import PageSeven from "./Pages/PageSeven";

import { HeaderSize } from "../../Constants/global";

const Portfolio = () => {
  return (
    <div style={{ paddingTop: HeaderSize.BottomPad }}>
      <PageOne />
      <PageTwo />
      <PageThree />
      {/* <PageFour /> */}
      {/* <PageFive /> */}
      {/* <PageSix /> */}
      {/* <PageSeven /> */}
    </div>
  );
};

export default Portfolio;