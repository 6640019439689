import React from "react";
import aboutMainPageImg from "../../../Assets/About-us-illustration.webp";
import "./PageOne.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";
import { useNavigate } from "react-router-dom";

const PageOne = () => {
  const [width, height] = useWindowSize();
  let navigate = useNavigate();

  return (
    <div className="aPageOne_bg">
      <div className="leftSection" data-aos={width < 992 ? "fade-down" : "fade-right"}>
        <div className="aPageOne_Italic">WE ARE HERE TO</div>
        <div className="aPageOne_title_one">BUILD INTERACTIVE </div>
        <div className="aPageOne_title_one">
          <span>AND ENGAGING</span>
          <span className="aPageOne_title_two"> GAMES</span>
        </div>
        <div className="aPageOne_title_three">
          Our vision is to create addictive HTML5 games to meet the
          entertainment needs of gaming enthusiasts while driving revenue for
          businesses as well.
        </div>
        <div className="aPageOne-box">
          <div className="aPageOne-box_text"
            onClick={() => {
              // navigate("/contact-us");
              window.open('/contact-us','_blank')
              // window.scrollTo(0, 0);
            }}>CONTACT US</div>
        </div>
      </div>
      <div className="rightSection" data-aos={width < 992 ? "fade-down" : "fade-left"}>
          <img
            src={aboutMainPageImg}
            alt="imgBtn"
            style={{ width: "98%", height: "100%", objectFit: "contain" }}
          />
      </div>
    </div>
  );
};

export default PageOne;
