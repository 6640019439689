import React from "react";
import { useLocation } from 'react-router-dom';

import "./PageOne.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../../Commons/Common";

const PageOne = (props) => {
  const [width, height] = useWindowSize();
  const { state } = useLocation();

  // console.log("Props: ", props);

  return (
    <div className="jdPageOne_bg" style={{ overflow: "hidden" }}>
      <div className="jdPageOne_row">
        <div
          className="jdPageOne_colOne"
          data-aos={width < 576 ? "fade-up" : "fade-up"}
        >
          {width < 576 ?
            <div className="jdPageOne_Italic2">
              WE ARE HIRING
            </div>
            :
            ""
}
          <div className="jdPageOne_title_one">
            {width <= 576 ? state.post_designationMob : state.post_designationMob}
          </div>
          {/* <div className="jdPageOne_Italic">Experience the Best !</div> */}
        </div>
      </div>
    </div>
  );
};

export default PageOne;