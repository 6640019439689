import React from "react";
import businessGrowImg from "../../../Assets/Grow-Business.webp";
import { useNavigate } from "react-router-dom";

import "./PageOne.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";

const PageOne = () => {
  const [width, height] = useWindowSize();
  let navigate = useNavigate();

  return (
    <div className="lPageOne_innerBg">
      <div className="lPageOne_bg">
        <div className="leftSectionL" data-aos={width < 992 ? "fade-down" : "fade-right"}>
          <div className="lPageOne_Italic">WE HELP YOU</div>
          <div className="lPageOne_title_one">TO GROW<br />YOUR BUSINESS</div>
          <div>
            <span className="lPageOne_title_two">By Integrating</span>
            <span className="lPageOne_title_three"> OUR GAMES</span>
          </div>
          <div className="lPageOne-box">
            <div className="lPageOne-box_text"
              onClick={() => {
                // navigate("/contact-us");
                window.open('/contact-us', '_blank')
                // window.scrollTo(0, 0);
              }}>LET'S GET STARTED</div>
          </div>
        </div>
        <div className="rightSectionL" data-aos={width < 992 ? "fade-down" : "fade-left"}>
          <img
            src={businessGrowImg}
            alt="imgBtn"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
      </div>
      <div className="lPageOne_bg_mobile">
        <div className="topSection" data-aos={width < 992 ? "fade-down" : "fade-right"}>
          <div className="textOne">WE HELP YOU</div>
          <div className="textTwo">TO GROW YOUR<br />BUSINESS BY</div>
          <div>
            <span className="textThreeOne">INTEGRATING</span>
            <span className="textThreeTwo"> OUR GAMES&nbsp;</span>
          </div>
          <div className="contactUsLicenseButton">
            <div className="contactUsLicenseButton_text"
              onClick={() => {
                // navigate("/contact-us");
                window.open('/contact-us', '_blank')
                // window.scrollTo(0, 0);
              }}>LET'S GET STARTED</div>
          </div>
        </div>
        <div className="bottomSection" data-aos={width < 992 ? "fade-down" : "fade-right"}>
          <img
            src={businessGrowImg}
            alt="imgBtn"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
      </div>
    </div>
  );
};

export default PageOne;
