import React from "react";
import { Row, Col } from "antd";

import "./PageSix.less";

import ECommerceImg from "../../../Assets/E commerce_.webp";
import OTTImg from "../../../Assets/OTT.webp";
import RMGImg from "../../../Assets/Real money gaming_.webp";
import TOperatorsImg from "../../../Assets/Telecom operators_.webp";
import SVAImg from "../../../Assets/Short vedio apps.webp";
import SMAImg from "../../../Assets/Social media apps_.webp";
import NewsAppsImg from "../../../Assets/news app_.webp";
import WAImg from "../../../Assets/wallet applications_.webp";

import { useWindowSize } from "../../Commons/Common";

const frData = [
  {
    key: "1",
    image: ECommerceImg,
    heading: "E-Commerce",
  },
  {
    key: "2",
    image: OTTImg,
    heading: "OTT",
  },
  {
    key: "3",
    image: RMGImg,
    heading: "Real Money Gaming",
  },
  {
    key: "4",
    image: TOperatorsImg,
    heading: "Telecom Operators",
  },
];

const srData = [
  {
    key: "1",
    image: SVAImg,
    heading: "Short Video Apps",
  },
  {
    key: "2",
    image: SMAImg,
    heading: "Social Media Apps",
  },
  {
    key: "3",
    image: NewsAppsImg,
    heading: "News Apps",
  },
  {
    key: "4",
    image: WAImg,
    heading: "Wallet Applications",
  },
];

const mobileData = [
  {
    key: "1",
    image: ECommerceImg,
    heading: "E-Commerce",
    bgColor: "#b0f2d7"
  },
  {
    key: "2",
    image: OTTImg,
    heading: "OTT",
    bgColor: "#91edc7"
  },
  {
    key: "3",
    image: RMGImg,
    heading: "Real Money Gaming",
    bgColor: "#72e8b7"
  },
  {
    key: "4",
    image: TOperatorsImg,
    heading: "Telecom Operators",
    bgColor: "#6ae7b3"
  },
  {
    key: "5",
    image: SVAImg,
    heading: "Short Video Apps",
    bgColor: "#58e4aa"
  },
  {
    key: "6",
    image: SMAImg,
    heading: "Social Media Apps",
    bgColor: "#46e1a1"
  },
  {
    key: "7",
    image: NewsAppsImg,
    heading: "News Apps",
    bgColor: "#35df98"
  },
  {
    key: "8",
    image: WAImg,
    heading: "Wallet Applications",
    bgColor: "#23dc8f"
  },
];



const PageSix = () => {
  const [width, height] = useWindowSize();

  var col = "yellow";

  return (
    <div className="aPageSix_marginTop">
      <div className="pageSix_Industries_bg_Desktop">
        <Row
          justify="space-evenly"
        // gutter={[
        //   width > 1200
        //     ? 80
        //     : width > 992
        //     ? 40
        //     : width > 768
        //     ? 40
        //     : width > 450
        //     ? 30
        //     : 10,
        //   width > 1200
        //     ? 80
        //     : width > 992
        //     ? 40
        //     : width > 768
        //     ? 40
        //     : width > 450
        //     ? 30
        //     : 10,
        // ]}
        >
          {frData &&
            frData.map((item, index) => {
              return (
                <>
                  <Col key={item.key.toString()} data-aos="zoom-in">
                    <div className="aPageSix_card">
                      <div className="aPageSix_img">
                        <img
                          src={item.image}
                          alt={item.image}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="aPageSix_card_content">{item.heading}</div>
                    </div>
                  </Col>
                </>
              );
            })}
        </Row>
        <Row
          justify="space-evenly"
          // gutter={[
          //   width > 1200
          //     ? 80
          //     : width > 992
          //     ? 40
          //     : width > 768
          //     ? 40
          //     : width > 450
          //     ? 30
          //     : 10,
          //   width > 1200
          //     ? 80
          //     : width > 992
          //     ? 40
          //     : width > 768
          //     ? 40
          //     : width > 450
          //     ? 30
          //     : 10,
          // ]}
          style={{
            marginTop:
              width > 1200 ? 30 : width > 992 ? 20 : width > 768 ? 40 : 0,
          }}
        >
          {srData &&
            srData.map((item) => {
              return (
                <>
                  <Col key={item.key.toString()} data-aos="zoom-in">
                    <div className="aPageSix_card">
                      <div className="aPageSix_img">
                        <img
                          src={item.image}
                          alt={item.image}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="aPageSix_card_content">{item.heading}</div>
                    </div>
                  </Col>
                </>
              );
            })}
        </Row>
      </div>
      <div className="pageSix_Industries_bg">
        {mobileData &&
          mobileData.map((item) => {
            return (
              <div className="pageSix_Industries_card" style={{
                backgroundColor: item.bgColor
              }}>
                <div className="pageSix_Industries_text"
                >
                  {item.heading}
                </div>
                <div className="pageSix_Industries_image">
                  <div className="pageSix_Industries_image_div">
                    <img
                      src={item.image}
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>

    </div>
  );
};

export default PageSix;
