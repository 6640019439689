import React from "react";

import PageOne from "./Pages/PageOne";
import PageTwo from "./Pages/PageTwo";
import PageThree from "./Pages/PageThree";
import PageFour from "./Pages/PageFour";
import PageFive from "./Pages/PageFive";
import PageSix from "./Pages/PageSix";
import PageSeven from "./Pages/PageSeven";
import PageEight from "./Pages/PageEight";
import PageNine from "./Pages/PageNine";

import { HeaderSize } from "../../Constants/global";

import './About.less'

const Abouts = () => {
  return (
    <div className="aboutUs" style={{ paddingTop: HeaderSize.BottomPad }}>
      <PageOne />
      <div className="pageTwoThreeFour">
        <PageTwo />
        <PageThree />
        <PageFour />
      </div>
      <PageFive />
      <PageSix />
      <PageSeven />
      <PageEight />
      <PageNine />
    </div>
  );
};

export default Abouts;
