import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import { Pagination } from "swiper";

import jobImage1 from "../../../Assets/business-development.webp";
import jobImage2 from "../../../Assets/CPL Tounament.webp";
import jobImage3 from "../../../Assets/game-dev-intern.webp";
import jobImage4 from "../../../Assets/CPL Tounament.webp";
import jobImage5 from "../../../Assets/unity-developer.webp";
import jobImage6 from "../../../Assets/CPL Tounament.webp";

import activeImage from "../../../Assets/testimonial-shape-1.webp";
import passiveImage from "../../../Assets/testimonial-shape.webp";

import quotationImg from "../../../Assets/Quotation.webp";
import activeQuotationImg from "../../../Assets/Quotation-1.webp";
import "./PageNine.less";
import { useNavigate } from "react-router-dom";

import { useWindowSize } from "../../Commons/Common";

// Data for Job openings
const rData = [
  {
    key: "1",
    content:
      " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minus voluptates nobis quis quidem laboriosam totam, cum eligendi quaequaerat exercitationem, recusandae vitae fuga sapiente. Itaquevoluptatum repellendus eum vero? Vitae.",
    image: jobImage1,
    name: "Minakshi Singh",
    designation: "Business Development Executive",
    designationMob: "Business Development Executive",
    openings: "5",
    experience: "1-3 years",
    type: "Full Time",
    rolesList: [
      'Bring up new clients on board.',
      'Research organizations and individuals online (mainly on social media) to generate leads and new markets for our HTML5 games.',
      'Partner with large content companies for the adoption of our HTML5 games to distribute them across their professional networks.',
      'Engage with the in-house team for various discussions.',
      'Develop a network with game publishers, local content providers, advertisers, etc. to release our games on their platforms on a licensing and revenue-sharing model in lieu of brand guidelines and company business objectives.',
      'Monitor, analyze and implement business strategies to increase sales and meet targets on a weekly and monthly basis.',
      'Know our product inside out to demonstrate it to potential clients effectively.',
      'Attend meetings, conferences, and other relevant industry events with your manager whenever needed.',
    ],
    skillsList: [
      'Bachelor’s degree is mandatory',
      'The candidate must have strong communication skills',
      'Ability to develop lasting relations with clients',
      'Must be fluent in the English language',
      'Should be self-organized, rigorous, detail-oriented, and proactive',
      'Experience in Business Development/Sales or any other related field',
      'Good decision-making and negotiation skills',
      'Being active in the Online Games community is a plus point',
    ],
    perksList: [
      '5 days a week',
      'Productive/Positive work culture',
      'Informal dress code',
      'Supportive management',
      'Scope for career growth'
    ]
  },
  // {
  //   key: "2",
  //   content:
  //     " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minus voluptates nobis quis quidem laboriosam totam, cum eligendi quaequaerat exercitationem, recusandae vitae fuga sapiente. Itaquevoluptatum repellendus eum vero? Vitae.",
  //   image: jobImage2,
  //   name: "Minakshi Gupta",
  //   designation: "Business Development Manager",
  //   designationMob: "Business Development Manager",
  //   openings: "2",
  //   experience: "Fresher",
  //   type: "Full Time",
  //   rolesList: [
  //     'Bring up new clients on board.',
  //     'Research organizations and individuals online (mainly on social media) to generate leads and new markets for our HTML5 games.',
  //     'Partner with large content companies for the adoption of our HTML5 games to distribute them across their professional networks.',
  //     'Lead and guide the business team.',
  //     'Maintain good relations with game publishers, local content providers, advertisers, etc.',
  //     'Monitor, analyze and implement business strategies to increase sales and meet the targets on a weekly and monthly basis.',
  //     'Know our product inside out so as to demonstrate it to potential clients effectively.',
  //     'Attend meetings, conferences, and other relevant industry events on behalf of the company.',
  //     'Must take informed decisions and consider the costs and financial plans of the company.',
  //     'Manage all the business related queries of the companies.',
  //   ],
  //   skillsList: [
  //     'Bachelor’s degree is mandatory. ',
  //     'The candidate must have strong communication and leadership skills.',
  //     'Ability to develop lasting relations with clients.',
  //     'Must be fluent in the English language.',
  //     'Should be self-organized, rigorous, detail-oriented, and proactive.',
  //     'Proven experience in Business Development and Sales.',
  //     'Good decision-making and negotiation skills.',
  //     'Being active in the Online Games community is a plus point.',
  //   ],
  //   perksList: [
  //     '5 days a week',
  //     'Productive/Positive work culture',
  //     'Informal dress code',
  //     'Health Insurance',
  //     'Supportive management',
  //     'Scope for career growth',
  //   ]
  // },
  // {
  //   key: "3",
  //   content:
  //     " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minus voluptates nobis quis quidem laboriosam totam, cum eligendi quaequaerat exercitationem, recusandae vitae fuga sapiente. Itaquevoluptatum repellendus eum vero? Vitae.",
  //   image: jobImage3,
  //   name: "Santraj Yadav",
  //   designation: "HTML5 Game Developer Intern",
  //   designationMob: "HTML5 Game Developer Intern",
  //   openings: "3",
  //   experience: "Fresher",
  //   type: "Full Time",
  //   rolesList: [
  //     'Develop 2D HTML5 games.',

  //     'Create and maintain reusable code and libraries for future use.',

  //     'Troubleshoot and fix code errors.',

  //     'Write code size and speed in a way to optimize the technological capacities of various devices.',

  //     'Coordinate with the design team for various requirements.',

  //     'Ensure the responsiveness of the games depending upon the platforms.',

  //     'Polish the older games when instructed.'
  //   ],
  //   skillsList: [
  //     "Completed Bachelor's degree",

  //     'Must know HTML language',

  //     'Experience/Knowledge of backend programming languages like JavaScript, Python, etc.',

  //     'Good problem-solving and analytical skills',

  //     'Remain up-to-date with the latest gaming technologies, trends and techniques',
  //   ],
  //   perksList: [
  //     'Certificate of Internship',
  //     'Letter of Recommendation',
  //     'Productive/Positive work culture',
  //     'Informal dress code',
  //     'Supportive management',
  //     'Scope for career growth',
  //     'Job offer',
  //   ]
  // },
  // {
  //   key: "4",
  //   content:
  //     " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minus voluptates nobis quis quidem laboriosam totam, cum eligendi quaequaerat exercitationem, recusandae vitae fuga sapiente. Itaquevoluptatum repellendus eum vero? Vitae.",
  //   image: jobImage4,
  //   name: "Minakshi Singh",
  //   designation: "Human Resources Executive",
  //   openings: "2",
  //   experience: "Fresher",
  //   type: "Full Time",
  //   rolesList: [
  //     'Plan and manage the hiring process of different departments.',
  //     'Cultivate a productive work culture for the team.',
  //     'Handle online job portals such as LinkedIn, Internshala, Angellist, etc.',
  //     'Oversee the communication with the candidates from the moment they apply until they are onboarded such as checking backgrounds, assigning tasks, salary negotiation, issuing offer letters, etc.',
  //     'Arranging and scheduling online/in-person interviews with the selected candidates.',
  //     'Report to management and help in decision-making.',
  //     'Maintain the pay schedules of all the employees.',
  //     'Monitor employee progress and create a positive environment that optimizes the performance of the team.',
  //     'In-house team management.',
  //   ],
  //   skillsList: [
  //     'Relevant bachelor’s degree is mandatory.',
  //     'Must have worked before in an HR or similar role.',
  //     'Knowledge of job portals like LinkedIn, Internshala, Naukri, Angellist, etc.',
  //     'Basic knowledge of Excel/Google Sheet',
  //     'Strong communication and interpersonal skills',
  //     'Relationship management with employees',
  //     'Good influencing and negotiation skills',
  //   ],
  //   perksList: [
  //     '5 days a week',
  //     'Productive/Positive work culture',
  //     'Informal dress code',
  //     'Supportive management',
  //     'Scope for career growth'
  //   ]
  // },
  // {
    // key: "5",
    // content:
    //   " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minus voluptates nobis quis quidem laboriosam totam, cum eligendi quaequaerat exercitationem, recusandae vitae fuga sapiente. Itaquevoluptatum repellendus eum vero? Vitae.",
    // image: jobImage5,
    // name: "Santraj Yadav",
    // designation: `Unity Game Developer`,
    // designationMob: "Unity Game Developer",
    // openings: "4",
    // experience: "2 Years",
    // type: "Full Time",
    // rolesList: [
    //   'Design, build and maintain, reusable and reliable code.',

    //   'Active participation in technical and artistic discussions.',

    //   'Ability to quickly get up to speed with existing code.',

    //   'Transforming design ideas/specifications into functional games.',

    //   'Identifying and fixing code errors and game bottlenecks.',

    //   'Design and develop core tools into the pipeline.',

    //   'Testing game functionality and theme dynamics.',

    //   'Ensuring the best performance, quality, and responsiveness of games.',
    // ],
    // skillsList: [
    //   "2+ years of experience working as a Unity or Unity3D Developer",

    //   'Strong, professional experience using Unity (preferred) or any other Game Engine',

    //   'Knowledge of 3D development and integrated game physics',

    //   'Experience with console and mobile game development',

    //   'Experience with memory and space optimization',

    //   '3D math skills',

    //   'Game designing skills are a definite plus!',
    // ],
    // perksList: [
    //   '5 days a week',
    //   'Productive/Positive work culture',
    //   'Informal dress code',
    //   'Supportive management',
    //   'Scope for career growth',
      // 'Scope for career growth',
      // 'Job offer',
//     ]
//   },
];

const PageNine = () => {

  let navigate = useNavigate();

  const [width, height] = useWindowSize();

  const [slideIndex, setSlideIndex] = useState(1);
  const [autoPlay, setAutoPlay] = useState(true);

  const moveDot = (index) => {
    setSlideIndex(index);
  };

  if (autoPlay) {
    setTimeout(() => {
      if (slideIndex === rData.length) {
        setSlideIndex(1);
      } else {
        setSlideIndex(slideIndex + 1);
      }
    }, 1000);
  }

  function currentCardData(items) {
    // console.log(items);
   navigate("/careers/careersJD",
      {
        state: {
          post_key: items.key,
          post_content: items.content,
          post_image: items.image,
          post_name: items.name,
          post_designation: items.designation,
          post_designationMob: items.designationMob,
          post_openings: items.openings,
          post_experience: items.experience,
          post_type: items.type,
          post_roleList: items.rolesList,
          post_skillsList: items.skillsList,
          post_perksList: items.perksList,
        }
      });
    window.scrollTo(0, 0);
  }

  return (
    <div id="WeAreHiring" className="crPageNine_bg" style={{ overflow: "hidden" }}>
      <div>
        <div className="crPageNine_heading">WE ARE HIRING</div>
        <div className="crPageNine_hBar_wrapper">
          <div className="acrPageNine_hBar">
            <div className="acrPageNine_hEl" />
          </div>
        </div>
      </div>

      <div className="carPageNineSwipperBody">
        <div className="pageNineBody_Desktop_carausel">
          <Swiper
            data-aos="fade-up"
            slidesPerView={width > 992 ? 3 : 2}
            spaceBetween={30}
            slidesPerGroup={1}
            loop={true}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loopFillGroupWithBlank={true}
            modules={[Autoplay, Pagination]}
            pagination={{
              // enabled: true,
              clickable: true,
              bulletClass: `swiper-pagination-bullet`,
              bulletActiveClass: "swiper-pagination-bullet-active",
            }}
            className="crPageNine_mySwiper"
            style={{
              width: width > 1200 ? "95%" : width > 992 ? "98%" : "100%",
            }}
            centeredSlides={width > 992 ? true : false}
          >
            {rData &&
              rData.map((item) => {
                return (
                  <SwiperSlide>
                    {({ isActive }) => (
                      <div
                        className="crPageNine_SwiperContainer"
                        style={{
                          transform:
                            isActive && width > 992 ? "scale(1.1)" : "scale(1)",
                        }}
                      >
                        <div className="crPageNine_cardInner">
                          <div className="cardContent1">
                            <img src="" alt="" />
                          </div>
                          <div className="cardContent1">
                            <img src={jobImage1} alt="" width="120px" />
                          </div>
                          <div className="cardContent2"><b>{item.designation}</b></div>
                          <div className="cardContent3"><b>Experience :&nbsp;</b>{item.experience}</div>
                          <div className="cardContent3"><b>Openings :&nbsp;</b>{item.openings}</div>
                          <div className="cardContent3"><b>Type :&nbsp;</b>{item.type}</div>
                          {/* <div className="cardContent4"> */}
                          <div className="cardButton">
                            <div className="cardButtonText"
                              onClick={() => {
                                currentCardData(item);
                              }}>
                              VIEW & APPLY
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    )}
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
        {/* ////////////////////////// */}
        <div className="pageNineBody_Desktop"
        style={{
          maxWidth: 1100,
          flexWrap: "wrap",
          justifyContent: "center"
        }}>
          {rData &&
            rData.map((item) => {
              return (
                <div
                  className="crPageNine_SwiperContainer"
                  style={{
                    marginBottom: "10px"
                  }}
                >
                  <div className="crPageNine_cardInner"
                    style={{

                      height: 430,
                      transform: "scale(0.9)",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}
                  >
                    <br />
                    <div>
                      <div className="cardContent1">
                        <img src={item.image} alt="" width={width <= 576 ? "95px" : "120px"} />
                      </div>
                      <div className="cardContent2"><b>{item.designation}</b></div>
                    </div>
                    <div>
                      <div className="cardContent3"><b>Experience : &nbsp; </b>{item.experience}</div>
                      <div className="cardContent3"><b>Openings : &nbsp; </b>{item.openings}</div>
                      <div className="cardContent3"><b>Type : &nbsp; </b>{item.type}</div>

                      {/* <div className="cardContent4"> */}
                      <div className="cardButton">
                        <div className="cardButtonText"
                          onClick={() => {
                            currentCardData(item);
                          }}>
                          VIEW & APPLY
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              );
            })}
        </div>
        {/* ////////////////////////// */}
        <div className="pageNineBody_Mobile">
          {rData &&
            rData.map((item) => {
              return (
                <div
                  className="crPageNine_SwiperContainer"
                  style={{
                  }}
                >
                  <div className="crPageNine_cardInner">
                    <div className="cardContent1">
                      <img src="" alt="" />
                    </div>
                    <div className="cardContent1">
                      <img src={item.image} alt="" width={width <= 576 ? "95px" : "120px"} />
                    </div>
                    <div className="cardContent2"><b>{item.designationMob}</b></div>
                    <div className="cardContent3"><b>Experience : &nbsp; </b>{item.experience}</div>
                    <div className="cardContent3"><b>Openings : &nbsp; </b>{item.openings}</div>
                    <div className="cardContent3"><b>Type : &nbsp; </b>{item.type}</div>
                    {/* <div className="cardContent4"> */}
                    <div className="cardButton">
                      <div className="cardButtonText"
                        onClick={() => {
                          currentCardData(item);
                        }}>
                        VIEW & APPLY
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              );
            })}
        </div>
        {/* <section className="crPageNine_sContainer" data-aos="fade-up">
          {rData &&
            rData.map((item, index) => {
              return (
                <div
                  key={item.key}
                  className={
                    slideIndex === index + 1
                      ? "crPageNine_slide active"
                      : "crPageNine_slide"
                  }
                >
                  <div
                    className="crPageNine_card"
                    onMouseEnter={() => setAutoPlay(false)}
                    onMouseLeave={() => setAutoPlay(true)}
                  >
                    <div className="crPageNine_quote">
                      <img
                        src={quotationImg}
                        alt="quotationBtn"
                        style={{ width: 30 }}
                      />
                    </div>
                    <div className="crPageNine_content">{item.content}</div>
                    <div className="crPageNine_img_wrapper">
                      <div className="crPageNine_img">
                        <img
                          src={item.image}
                          alt="cardImg"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="crPageNine_text_one">{item.name}</div>
                      <div className="crPageNine_text_two">{item.designation}</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </section> */}
        {/* <div className="crPageNine_dContainer">
          {rData &&
            rData.map((item, index) => {
              return (
                <>
                  <div
                    onClick={() => {
                      moveDot(index + 1);
                      setAutoPlay(false);
                    }}
                    onMouseLeave={() => setAutoPlay(true)}
                    className={
                      slideIndex === index + 1
                        ? "crPageNine_sDot active"
                        : "crPageNine_sDot"
                    }
                  />
                </>
              );
            })}
        </div> */}
      </div>
    </div>
  );
};

export default PageNine;
