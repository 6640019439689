import React from "react";

import MXPlayerImg from "../../Assets/Viacom-18.webp";
import PhonePayImg from "../../Assets/Phone-Pay.webp";
import YandexImg from "../../Assets/Yandex.webp";
import ViacomImg from "../../Assets/Viacom-18.webp";
import PaytmImg from "../../Assets/Paytm.webp";

import "./IConsAnimation.less";

const IconsAnimation = () => {
  return (
    <div className="pageEight_icons_container" style={{
      overflowX: "hidden"
    }}>
      <div className="pageEight_icons">
        <div className="pageEight_icon">
          <img
            src={MXPlayerImg}
            alt="ImgBtn"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
        <div className="pageEight_icon">
          <img
            src={PhonePayImg}
            alt="ImgBtn"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>{" "}
        <div className="pageEight_icon">
          <img
            src={YandexImg}
            alt="ImgBtn"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>{" "}
        <div className="pageEight_icon">
          <img
            src={ViacomImg}
            alt="ImgBtn"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>{" "}
        <div className="pageEight_icon">
          <img
            src={PaytmImg}
            alt="ImgBtn"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
      </div>
    </div>
  );
};

export default IconsAnimation;
