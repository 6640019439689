// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/X-Brandiung-gradient.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XBrand_PageFourFive {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: top;\n}\n@media (max-width: 576px) and (min-width: 992px) {\n  .XBrand_PageFourFive {\n    background-image: none;\n  }\n}\n@media screen and (max-width: 576px) {\n  .XBrand_PageFourFive {\n    background-image: none;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Homes/HomePage.less"],"names":[],"mappings":"AAMA;EACI,yDAAA;EACA,wBAAA;AALJ;AAQA;EACI;IACI,sBAAA;EANN;AACF;AASA;EACI;IACI,sBAAA;EAPN;AACF","sourcesContent":["@brand-primary: #21ce86;\n@brand-secondary: #4e7884;\n@brand-ternary: #185563;\n@brand-quaternary: #029989;\n@brand-white: #ffffff;\n\n.XBrand_PageFourFive {\n    background-image: url(\"../../Assets/X-Brandiung-gradient.webp\");\n    background-position: top;\n}\n\n@media (max-width: 576px) and (min-width: 992px) {\n    .XBrand_PageFourFive {\n        background-image: none;\n    }\n}\n\n@media screen and (max-width: 576px) {\n    .XBrand_PageFourFive {\n        background-image: none;\n    }\n}\n@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
