/* eslint-disable no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";

import "./ConnectUs.less";

import { Form, Input, Button } from "antd";
import { useWindowSize } from "../Commons/Common";
// import { getValue } from "@testing-library/user-event/dist/utils";
// import emailjs from '@emailjs/browser';
// import { SendMailClient } from "zeptomail";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const ConnectUs = (props) => {

  const [captcha, setCaptcha] = useState(null);
  const captchaRef = useRef();

  function onChange(value) {
    console.log("Captcha value:", value);
    setCaptcha(value);
  }

  const [width, height] = useWindowSize();
  const [form] = Form.useForm();
  //   const nameValue = Form.useWatch("name", form);

  const sendEmail = (e) => {
    console.log("Website Name:", document.getElementById('formWebsiteName').value);
    console.log("To Email:", document.getElementById('formToEmail').value);
    // console.log("Email:", document.getElementById('formEmail').value);
    // console.log("Number:", document.getElementById('formNumber').value);
    // console.log("Message:", document.getElementById('formMessage').value);

    var name = String(document.getElementById('formName').value);
    var email = String(document.getElementById('formEmail').value);
    var number = String(document.getElementById('formNumber').value);
    var message = String(document.getElementById('formMessage').value);
    var websiteName = String(document.getElementById('formWebsiteName').value);
    var toEmail = String(document.getElementById('formToEmail').value);
    e.preventDefault();

    var button1 = document.getElementById("submitButton");
    var text1 = document.getElementById("buttonText");
    button1.disabled = true;

    console.log("Form Data Contact Us:\n", name, "\n", email, "\n", number, "\n", message, "\n", websiteName, "\n", toEmail);

    if (name.length > 0 && email.length > 0 && message.length > 0 && (captcha !== null && captcha !== "null")) {
      button1.style.backgroundColor = "grey";
      text1.textContent = "Sending...";
      // button1.style.backgroundColor = "white";

      let data = JSON.stringify({
        "from": {
          "address": "noreply@freakxgames.com"
        },
        "to": [
          {
            "email_address": {
              "address": "hello@freakxapps.com",
              "name": "Aashish arorah"
            }
          }
        ],
        "subject": `${websiteName} -- Contact Us`,
        "htmlbody": `<div><h3>Hello Freak X Games,</h3><br/><ul><li>Name: ${name}</li><li>Email: ${email}</li><li>Phone: ${number}</li><li>Message: ${message}</li></ul></div>`
      });

      let config = {
        method: 'post',
        url: 'https://cors.gameskite.com/https://api.zeptomail.in/v1.1/email',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Zoho-enczapikey PHtE6r1cF7y+32B9pkRVtqS+Ec+gZIgr+OlhKwhFt4hLW/dVSk1c/9wqxzKx/00iUfgTQv/IzN1g4LnP4bmEdmjlYGwdW2qyqK3sx/VYSPOZsbq6x00cslQdc03bVIXmddJu3SXWutjfNA==',
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log("Response: ", response);
          console.log(JSON.stringify(response.data));
          e.target.reset();
          text1.textContent = `Thanks for your message.`;
          setTimeout(() => {
            button1.disabled = false;
            text1.textContent = "Our team will contact you ASAP";
          }, 2000);
          setTimeout(() => {
            button1.style.backgroundColor = "#21ce86";
            button1.disabled = false;
            text1.textContent = width > 576 ? props.buttonText : props.buttonTextMobile;
            window.location.reload();
          }, 4000);
        })
        .catch((error) => {
          // captchaRef.current.resetCaptcha();
          console.log(error);
          text1.textContent = "Technical issue!!! Try later.";
          console.log(error.text);
          e.target.reset();
          button1.style.backgroundColor = "grey";
          setTimeout(() => {
            button1.style.backgroundColor = "#21ce86";
            button1.disabled = false;
            text1.textContent = width > 576 ? props.buttonText : props.buttonTextMobile;
            window.location.reload();
          }, 2000);
        });


      // emailjs.sendForm('service_60mrwi1', 'template_grbqp4k', document.getElementById('myForm'), 'gAWHpl3Tddh-gc9UU')
      //   .then((result) => {
      //     console.log(result.text);
      //     e.target.reset();
      //     text1.textContent = `Thanks for your message.`;
      //     setTimeout(() => {
      //       button1.disabled = false;
      //       text1.textContent = "Our team will contact you ASAP";
      //     }, 2000);
      //     setTimeout(() => {
      //       button1.style.backgroundColor = "#21ce86";
      //       button1.disabled = false;
      //       text1.textContent = width > 576 ? props.buttonText : props.buttonTextMobile;
      //     }, 4000);
      //     // alert("Email Sent!")
      //   }, (error) => {
      //     text1.textContent = "Technical issue!!! Try later.";
      //     console.log(error.text);
      //     e.target.reset();
      //     button1.style.backgroundColor = "grey";
      //     setTimeout(() => {
      //       button1.style.backgroundColor = "#21ce86";
      //       button1.disabled = false;
      //       text1.textContent = width > 576 ? props.buttonText : props.buttonTextMobile;
      //     }, 2000);
      //   });
      // console.log("IF");
    }
    else {
      // console.log("ELSE");
      if (name.length > 0 && email.length > 0 && message.length > 0) {
        if (captcha == null || captcha == "" || captcha == "null") {
          text1.textContent = "Please fill the captcha";
        }
      }
      else {
        text1.textContent = "Please fill the required details";
      }

      button1.style.backgroundColor = "grey";
      setTimeout(() => {
        button1.style.backgroundColor = "#21ce86";
        button1.disabled = false;
        text1.textContent = width > 576 ? props.buttonText : props.buttonTextMobile;
      }, 2000);
    }
  };

  return (
    <div className={width < 576 ? "" : props.className}>
      {props.children}
      <div className="connectUs_heading" style={{
        textAlign: props.textAlign
      }}>
        {width > 576 ? props.title : ""}
      </div>
      <Form
        id="myForm"
        form={form}
        onSubmitCapture={sendEmail}
        layout="vertical"
        autoComplete="on"
        style={{
          fontSize: 16,
          fontWeight: 600,
          textAlign: "start",
          color: "#6c6d6c",
        }}
      >
        <Form.Item
          name="name"
          label="Full Name"
          style={{ marginBottom: 15, color: "#6c6d6c" }}
          rules={[
            {
              required: true,
              message: 'Please enter your name!',
            },
          ]}
        >
          <Input
            name="name"
            id="formName"
            // placeholder="Enter your name"
            className="contactUsInputBox"
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: "#6c6d6c",
              borderWidth: 1.5
            }}
            rules={[
              {
                required: true,
                message: 'Please enter your name!',
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 15 }}
          name="email"
          label="Email Address"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input
            name="email"
            id="formEmail"
            // placeholder="Enter your email address"
            className="contactUsInputBox"
            style={{
              fontSize: 16, fontWeight: 600, color: "#6c6d6c",
              borderWidth: 1.5
            }}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Mobile Number"
          style={{ marginBottom: 15 }}
        // rules={[
        //   {
        //     required: true,
        //     message: "Please input your phone number",
        //   },
        // ]}
        >
          <Input
            name="phone"
            id="formNumber"
            // placeholder="Enter your phone number"
            className="contactUsInputBox"
            style={{
              fontSize: 16, fontWeight: 600, color: "#6c6d6c",
              borderWidth: 1.5
            }}
          />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 25 }}
          name="message"
          label="Message"
          rules={[
            {
              required: true,
              message: "Please input message",
            },
          ]}
        >
          <Input.TextArea
            name="message"
            id="formMessage"
            rows={3}
            // placeholder="Type your message"
            className="contactUsInputBox"
            style={{
              fontSize: 16, fontWeight: 600, color: "#6c6d6c",
              borderWidth: 1.5
            }}
          />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 25, display: 'none' }}
          name="websiteName"
          label="WebsiteName"
          rules={[
            {
              required: true,
              message: "Please input message",
            },
          ]}
        >
          <Input.TextArea
            name="websiteName"
            id="formWebsiteName"
            rows={3}
            // placeholder="Type your message"
            className="contactUsInputBox"
            style={{
              fontSize: 16, fontWeight: 600, color: "#6c6d6c",
              borderWidth: 1.5
            }}
            defaultValue={'Freak X Games'}
          />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 25, display: 'none' }}
          name="toEmail"
          label="toEmail"
          rules={[
            {
              required: true,
              message: "Please input message",
            },
          ]}
        >
          <Input.TextArea
            name="toEmail"
            id="formToEmail"
            rows={3}
            // placeholder="Type your message"
            className="contactUsInputBox"
            style={{
              fontSize: 16, fontWeight: 600, color: "#6c6d6c",
              borderWidth: 1.5
            }}
            defaultValue={'hello@freakxapps.com'}
          />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 25, zIndex: 99999, position: 'relative' }}
        >
          <ReCAPTCHA
            sitekey="6LeqDQwqAAAAAABsmlwj8QHZGe5WhttTWEG4_bhA"
            onChange={onChange}
          // ref={captchaRef}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 10 }}>
          <Button className="formButton" id="submitButton" type="primary" htmlType="submit">
            <div className="formButton_text" id="buttonText">
              {width > 576 ? props.buttonText : props.buttonTextMobile}
            </div>
          </Button>
        </Form.Item>
      </Form>
    </div >
  );
};

export default ConnectUs;
