import React from "react";

import Collapse from "../../Commons/Collapse";

import "./PageSix.less";
import { Row, Col } from "antd";

const cData = [
  {
    key: "1",
    question: "How the portal solution can help you or your platform?",
    answer1:
      `Our portal cannot only do wonders in bringing more traffic to your platform but also offers innovative ways from which you can earn good revenue.`,
  },
  {
    key: "3",
    question: "How is the revenue generated in the portal solution?",
    answer1:
      "In the portal solution, all the revenue generated is via in-game advertising.",
  },
  {
    key: "4",
    question: "How much eCPM goes on our portal?",
    answer1:
      "It completely depends on the nature of the traffic and location as well.",
  },
  {
    key: "5",
    question: "How much can you earn with our portal solution?",
    answer1:
      "This will depend on the eCPM you get on the portal and the traffic on your platform.",
  },
  {
    key: "2",
    question: "Do you need to pay anything for the portal solution?",
    answer1:
      "No, it’s free of cost. You don’t need to pay anything for the portal solution.",
  },

];

const PageSix = () => {
  return (
    <div className="sPageSix_bg">
      <div className="sPageSix_Heading">FREQUENTLY ASKED QUESTIONS</div>
      <div className="sPageSix_hBar_wrapper">
        <div className="sPageSix_hBar">
          <div className="sPageSix_hEl" />
        </div>
      </div>

      <div className="sPageSix_Body">
        <Row justify="center" data-aos="fade-up">
          <Col xs={24} sm={22} md={21} lg={20} xl={18}>
            <Collapse data={cData} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PageSix;
