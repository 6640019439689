import React from "react";
import { useNavigate } from "react-router-dom";

import "./PageThree.less";

const PageThree = () => {
    const navigate = useNavigate();
    return (
        <div className="pageThree_bg">
            <div className="pageThree_Port_bg">
                <div className="textDiv">
                    <div className="pageThree_Port_text_kidse">Contact us for the Demo Links!</div>
                    <div className="pageThree_Port_text2_kidse">Looking to add our educational kids games?</div>
                </div>
                <div className="pageThree_Port_button" onClick={() => {
                    // navigate("/contact-us");
                    window.open('/contact-us','_blank')
                    // window.scrollTo(0, 0);
                }}>CONTACT US</div>
            </div>
        </div>
    );
};

export default PageThree;
