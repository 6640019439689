import React, { useState } from "react";
import "./App.less";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Headers/Headers";
import SideDrawer from "./Components/Headers/SideDrawer";
import BackDrop from "./Components/Headers/BackDrop";
import HomePage from "./Components/Homes/HomePage";
import SolutionPage from "./Components/Solutions/SolutionPage";
import PortfolioPage from "./Components/Portfolios/Portfolio";
import Abouts from "./Components/Abouts/Abouts";
import Licensing from "./Components/Licensing/Licensing";
import Blogs from "./Components/Blogs/Blogs";
import Careers from "./Components/Careers/Careers";
import CareersJD from "./Components/Careers/CareersJD/CareersJD";
import Contacts from "./Components/Contacts/Contacts";
import TermsAndConditions from "./Components/TermsAndConditions/TermsAndConditionsPage";
import Footer from "./Components/Footers/Footer";
import NotFound from "./Components/NotFound/NotFound";
import Portfolio from "./Components/Portfolios/Portfolio";
import KidsELearning from "./Components/KidsELearning/KidsELearningGames";


function App() {

  const [toggle, setToggle] = useState(false);

  const toggleHandler = () => {
    setToggle(!toggle);
  };

  const closeHandler = () => {
    setToggle(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <BrowserRouter>
        <Header SideDrawerHandler={toggleHandler} />
        <SideDrawer show={toggle} closeHandler={closeHandler} />
        {toggle && <BackDrop closeHandler={closeHandler} />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/casual-gaming-solution" element={<SolutionPage />} />
          <Route path="/our-html5-games-portfolio" element={<PortfolioPage />} />
          <Route path="/about-us" element={<Abouts />} />
          <Route path="/license-html5-games" element={<Licensing />} />
          <Route path="/kids-e-learning-games" element={<KidsELearning />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/careers/careersJD" element={<CareersJD />} />
          <Route path="/contact-us" element={<Contacts />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
