import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./PageTen.less";
import { Col, Row } from "antd";




// Last 7
// import banner92 from "../../../Assets/Kids-E- Games-Banners/addition.webp";
// import banner91 from "../../../Assets/Banner/captain-gold.webp";
// import banner90 from "../../../Assets/Banner/The Dandy.webp";
import banner4 from "../../../Assets/Kids-E- Games-Banners/addition.webp";
import banner9 from "../../../Assets/Kids-E- Games-Banners/spelling game.webp";
import banner6 from "../../../Assets/Kids-E- Games-Banners/multiplication.webp";
import banner7 from "../../../Assets/Kids-E- Games-Banners/picture puzzle.webp";



// Rest all games
import banner5 from "../../../Assets/Kids-E- Games-Banners/subtraction.webp";
import banner3 from "../../../Assets/Kids-E- Games-Banners/matchmaking.webp";
import banner8 from "../../../Assets/Kids-E- Games-Banners/division.webp";
import banner1 from "../../../Assets/Kids-E- Games-Banners/abc.webp";
import banner2 from "../../../Assets/Kids-E- Games-Banners/123.webp";

import xBrandx from "../../../Assets/x-branding-icon.webp";

// import banner8 from "../../../Assets/Banner/birds vs blocks.webp";

const gameDataHead = [
  // {
  //   key: "92", // 'captain-gold.webp'
  //   gameName: "ADDITION",
  //   gameImg: banner92,
  //   gameLink: "https://test.gameskite.com/testing/vipin/KIDSKITE/ADDITION/v27/index.html",
  // },
  // {
  //   key: "91", // 'captain-gold.webp'
  //   gameName: "Captain Gold",
  //   gameImg: banner91,
  //   gameLink: "https://gameskite.com/play/captain-gold?id=portal",
  // },
  // {
  //   key: "90", // 'The Dandy.webp'
  //   gameName: "The Dandy",
  //   gameImg: banner90,
  //   gameLink: "https://gameskite.com/play/the-dandy?id=portal",
  // },

]

const gameDataBody = [
  {
    key: "1", // 'ABC .webp'
    gameName: "ABC Learning",
    gameImg: banner1,
    gameLink: "https://kids.freakxgames.com/abc-game/v4/",
  },
  {
    key: "2", // 'Number Learning 123'
    gameName: "Number Learning",
    gameImg: banner2,
    gameLink: "https://kids.freakxgames.com/123-game/v5/",
  },
  {
    key: "9", // 'SPELLING.webp'
    gameName: "Spelling Learning",
    gameImg: banner9,
    gameLink: "https://kids.freakxgames.com/spelling-game/v6/",
  },
  {
    key: "7", // 'PICTURE PUZZLE.webp'
    gameName: "SHAPE PUZZLE",
    gameImg: banner7,
    gameLink: "https://kids.freakxgames.com/shape-puzzle/v5/",
  },
 
  {
    key: "3", // 'MATCHMAKING.webp'
    gameName: "MATCHMAKING",
    gameImg: banner3,
    gameLink: "https://kids.freakxgames.com/match-making/v8/",
  },
  {
    key: "4", // 'ADDITION.webp'
    gameName: "ADDITION",
    gameImg: banner4,
    gameLink: "https://kids.freakxgames.com/addition-game/v6/",
  },
  {
    key: "5", // 'SUBTRACTION.webp'
    gameName: "SUBTRACTION",
    gameImg: banner5,
    gameLink: "https://kids.freakxgames.com/subtraction-game/v4/",
  },
  {
    key: "6", // 'MULTIPLICATION.webp'
    gameName: "MULTIPLICATION",
    gameImg: banner6,
    gameLink: "https://kids.freakxgames.com/multiply-game/v5/",
  },
  // {
  //   key: "8", // 'Division.webp'
  //   gameName: "Division",
  //   gameImg: banner8,
  //   gameLink: "https://kids.freakxgames.com/divide-game/v4/",
  // },
  // {
  //   key: "1", // 'Drawing.webp'
  //   gameName: "Drawing",
  //   gameImg: banner1,
  //   gameLink: "https://gameskite.com/play/cricket-2020-online?id=portal",
  // },
];

const gameDataBody2 = [
    // {
    //   key: "81", // 'Cricket 2022.webp'
    //   gameName: "Cricket 2022",
    //   gameImg: banner81,
    //   gameLink: "https://gameskite.com/play/cricket-2020-online?id=portal",
    // },
];

const PageTwo = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);
  const [activeIndexPosition, setActiveIndexPosition] = useState();
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="pPageTwo_Background" style={{ paddingTop: 24, paddingBottom: 24 }}>
      <div className="pageOne_xBrand">
        <img src={xBrandx} alt="" height={"100px"} width={"130px"} />
      </div>
      <div className="pageOne_xBrand2">
        <img src={xBrandx} alt="" height={"55px"} width={"70px"} />
      </div>
      <Row justify="center">
        <Col className="pf_page_two_ourgames_home"><span className="kidse">KIDS E-LEARNING </span><span className="mobileview">GAMES</span> </Col>
      </Row>
      <div
        style={{
          padding: "0 15px",
          justifyContent: "center",
          align_items: "center",
          display: "flex",
          marginTop: 5,
        }}
      >
        <div className="pf_pageTwo_hBar_Home">
          <div className="pf_page_two_hEl_Home" />
        </div>
      </div>

      <div className="pageTwo_AllGamesCard">
        <div className="pageTwo_HeadCardDesktop">
          {gameDataHead &&
            gameDataHead.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild setHeaderFont">
                      <img
                        className="bodyCardImage"
                        src={game.gameImg}
                        alt="Geeks Img"
                        width={360}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
        <div className="pageTwo_HeadCardMobile">
          {gameDataHead &&
            gameDataHead.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild">
                      <img
                        className="bodyCardImage_home"
                        src={game.gameImg}
                        alt=""
                        width={270}
                      // height={"100%"}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
        <div className="pageTwo_BodyCard_home">
          {gameDataBody &&
            gameDataBody.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild_kids setBodyFontTenPage">
                      <img
                        className="bodyCardImage_home"
                        src={game.gameImg}
                        alt=""
                        width={270}
                      // height={"100%"}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
          {
            showMore === true
            &&
            gameDataBody2 &&
            gameDataBody2.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild">
                      <img
                        className="bodyCardImage"
                        src={game.gameImg}
                        alt=""
                        width={260}
                      // height={"100%"}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // margin: "10px 15px 15px 15px",
        }}
      >
        <div>
          <div
            className="pageTwo_explore_moreTen "
            onClick={() => {
              // setShowMore(!showMore);
              window.open('/kids-e-learning-games', '_blank');
              // navigate("/our-html5-games-portfolio");
              // window.scrollTo(0, 0);
            }}
          >
            EXPLORE MORE
          </div>
          {/* <div>
            {showMore === true ? "LOAD LESS" : "LOAD MORE"}
          </div> */}
        </div>

        {/* <div className="pf_pageTwo_explore_more">Load MORE</div> */}
      </div >
    </div >
  );
};

export default PageTwo;
