import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import error from "../../../Assets/404-desktop-design.webp";
import errorM from "../../../Assets/404-Mobile-design.webp";
import "./PagesOne.less";

const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/blog" || window.location.pathname === "/blogs") {
      window.location.replace("https://blog.freakxgames.com");
    }
  }, []);

  if (window.location.pathname === "/blog") {
    // Redirecting to the blog URL, no need to render NotFoundPage
    return null;
  }

  return (
    <div className="NotFoundPage">
      <div className="DesktopErrorPage">
        <img src={error} alt="error404" height="100%" width="100%" />
        <div
          className="PageOne_404error_button"
          onClick={() => {
            navigate("/");
            window.scrollTo(0, 0);
          }}
        >
          Go to Homepage
        </div>
      </div>

      <div className="MobileErrorPage">
        <img src={errorM} alt="error404" height="100%" width="100%" />
        <div
          className="PageOne_404error_button"
          onClick={() => {
            navigate("/");
            window.scrollTo(0, 0);
          }}
        >
          Go to Homepage
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
