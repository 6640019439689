import React from "react";

import "./PageOne.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";

const PageOne = () => {
  const [width, height] = useWindowSize();

  return (
    <div className="pfPageOne_bg" style={{ overflow: "hidden" }}>
      <div className="pfPageOne_row">
        <div
          className="pfPageOne_colOne"
          data-aos={width < 992 ? "fade-up" : "fade-up"}
        >
          <div className="pfPageOne_title_one">
          KIDS E-LEARNING GAMES
          </div>
          <div className="pfPageOne_Italic">Dive into a world of fun with our curated learning <span style={{fontWeight: "700"}}>games for kids</span>!</div>
        </div>
      </div>
    </div>
  );
};

export default PageOne;