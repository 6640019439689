import React from "react";

import "./PageOne.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";

const PageOne = () => {
  const [width, height] = useWindowSize();

  return (
    <div className="pfPageOne_bg" style={{ overflow: "hidden" }}>
      <div className="pfPageOne_row">
        <div
          className="pfPageOne_colOne"
          data-aos={width < 992 ? "fade-up" : "fade-up"}
        >
          <div className="pfPageOne_title_one_port">
          OUR HTML5 GAMES
          </div>
          <div className="pfPageOne_Italic">Discover the best in gaming with our premium <span style={{fontWeight: "600"}}>games collection</span>!</div>
        </div>
      </div>
    </div>
  );
};

export default PageOne;