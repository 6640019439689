import React from "react";
import ConnectUs from "../../Commons/ConnectUs";
import ofcBoy from "../../../Assets/officeguy new.webp";

import xBrandingL from "../../../Assets/x-branding-icon.webp";
import grass1 from "../../../Assets/grass_s.webp";
import grass2 from "../../../Assets/grass_b.webp";
import grass3 from "../../../Assets/grass_s.webp";

import "./PageSeven.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";

const PageSeven = () => {
  const [width, height] = useWindowSize();
  return (
    <div className="sPageSeven_bg">
      <div className="pageSevenDesk">
        <div className="lPageSeven_bg">
          {/* <div className="xBrandingL">
            <img src={xBrandingL} alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div> */}
          <div className="grass1">
            <img src={grass1} alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
          <div className="grass2">
            <img src={grass2} alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
          <div className="grass3">
            <img src={grass3} alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
          <div className="pageSeven_Body">
            <Row
              justify="space-between"
              gutter={[50, 30]}
            // style={{ overflow: "hidden" }}
            >
              <Col
                xs={24}
                sm={18}
                md={12}
                lg={10}
                xl={9}
                align="start"
                data-aos="fade-up"
                style={{
                  marginTop: width <= 576 ? 0 : -85
                }}
              >
                <div>
                  <div>
                    <div className="lPageSeven_Italic">JOIN TODAY</div>
                    <div className="sPageSeven_title_one">
                      BECOME OUR TOP<br />
                      REVENUE-PARTNER
                    </div>
                    <div className="sPageSeven_hBar_wrapper">
                      <div className="sPageSeven_hBar">
                        <div className="sPageSeven_hEl" />
                      </div>
                    </div>
                    <div className="lPageSeven_title_two">
                      Integrate our games into your platform on a revenue-sharing basis.
                    </div>
                  </div>
                  <div className="xBrandingL">
                    <img src={xBrandingL} alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                sm={18}
                md={12}
                lg={11}
                xl={9}
                align="center"
                data-aos="fade-up"
                style={{
                  marginTop: width <= 576 ? "0px" : "-75px"
                }}
              >
                <ConnectUs
                  className="connectUs_bg_Licensing"
                  title="CONNECT WITH US!"
                  textAlign="center"
                  buttonText="Submit"
                  buttonTextMobile="Connect with Us!"
                >
                  <div className="lPageSeven_img">
                    <img
                      src={ofcBoy}
                      alt="ofcGuyBtn"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        marginBottom: -5,
                      }}
                    />
                  </div>
                </ConnectUs>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="sPageSevenMobile_Body">
        <Row
          justify={
            width < 768
              ? "center"
              : width < 1200
                ? "space-around"
                : "space-between"
          }
          gutter={[10, 15]}
        // style={{ overflow: "hidden" }}
        >
          <Col
            xs={24}
            sm={18}
            md={12}
            lg={10}
            xl={9}
            align="start"
            data-aos="fade-up"
          >
            <div className="sPageSeven_Italic">JOIN TODAY</div>
            <div className="sPageSeven_title_one">
              BECOME OUR TOP REVENUE PARTNER
            </div>
            <div className="sPageSeven_hBar_wrapper">
              <div className="sPageSeven_hBar">
                <div className="sPageSeven_hEl" />
              </div>
            </div>
            <div className="sPageSeven_title_two">
              Integrate our games on your platform and generate additional
              revenue.
            </div>
          </Col>

          <Col
            xs={24}
            sm={18}
            md={12}
            lg={11}
            xl={9}
            align="center"
            data-aos="fade-up"
          >
            <ConnectUs
              className="connectUs_bg_RevenueSharing"
              title="CONNECT WITH US!"
              textAlign="center"
              buttonText="Submit"
              buttonTextMobile="Connect with Us!"
            >
              <div className="sPageSeven_img">
                <img
                  src={ofcBoy}
                  alt="ofcGuyBtn"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    marginBottom: 75,
                  }}
                />
              </div>
            </ConnectUs>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PageSeven;
