import React, { useState } from "react";

import plusImg from "../../Assets/Plus_D.webp";
import ninusImg from "../../Assets/Minus_L.webp";

import { Colors } from "../../Constants/Colors";
import { useWindowSize } from "./Common";

import "./Collapse.less";

const Collapse = (props) => {
  const [width, height] = useWindowSize();

  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <div>
      <div className="accordion">
        {props.data.map((item, i) => {
          return (
            <div key={item.key}>
              <div
                className="collapse_container"
                onClick={() => toggle(i)}
                style={{
                  borderTop: selected == i ? `2px solid ${Colors.white}` : `2px solid rgb(33 206 134 / 42%)`, //-Top-//
                  borderRight:
                    selected == i
                      ? `2px solid ${Colors.white}`
                      : width > 576
                      ? ``
                      : "",
                  borderBottom:
                    selected == i ? `` : `rgb(33 206 134 / 42%)`,
                  borderLeft:
                    selected == i
                      ? `2px solid ${Colors.white}`
                      : width > 576
                      ? `2px solid rgb(33 206 134 / 42%)`
                      : "",
                  color: selected == i ? "white" : "",
                  background: selected == i ? "#4e7884" : "",
                }}
              >
                <div className="collapse_container_content">
                  <div>
                    {selected == i ? (
                      <div className="accordion_ninus">
                        <img
                          src={ninusImg}
                          alt="ninusBtn"
                          style={{
                            width:
                              width < 576
                                ? 12
                                : width > 576
                                ? 17
                                : width > 768
                                ? 19
                                : width > 992
                                ? 21
                                : 21,
                            height:
                              width < 576
                                ? 12
                                : width > 576
                                ? 17
                                : width > 768
                                ? 19
                                : width > 992
                                ? 21
                                : 21,
                          }}
                        />
                      </div>
                    ) : (
                      <div className="accordion_plus">
                        <img
                          src={plusImg}
                          alt="ninusBtn"
                          style={{
                            width:
                              width < 576
                                ? 12
                                : width > 576
                                ? 17
                                : width > 768
                                ? 19
                                : width > 992
                                ? 21
                                : 21,
                            height:
                              width < 576
                                ? 12
                                : width > 576
                                ? 17
                                : width > 768
                                ? 19
                                : width > 992
                                ? 21
                                : 21,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div>{item.question}</div>
                </div>
              </div>
              {selected == i && (
                <div className="collapse_sContainer" onClick={() => toggle(i)}>
                  <div className="collapse_sContainer_content">
                    <div className="answerStyling">{item.answer1}</div>
                    {item.break1? <br />:null}
                    <div className="answerStyling">{item.answer2}</div>
                    {item.break2? <br />:null}
                    <div className="answerStyling">{item.answer3}</div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Collapse;
