import React from "react";

import provideApiImg from "../../../Assets/Provide-API.webp";
import "./PageFive.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";

const PageFive = () => {
  const [width, height] = useWindowSize();

  return (
    <div className="sPageFive_bg" style={{ overflow: "hidden" }}>
      <div className="sPageFive_Heading">
        SCORE API - PROVIDE SCORE FROM GAMES
      </div>
      <div className="sPageFive_hBar_wrapper">
        <div className="sPageFive_hBar">
          <div className="sPageFive_hEl" />
        </div>
      </div>
      <div className="sPageFive_Body">
        <Row justify="center" gutter={[20, 20]} align="middle">
          <Col
            xs={22}
            sm={18}
            md={12}
            lg={11}
            xl={10}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="sPageFive_img">
              <img
                src={provideApiImg}
                alt="provideApiImg"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={12}
            xl={10}
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="sPageFive_content_one">
              As a leading HTML5 game development company, we not only provide you with
              games but also give you options to gratify your users by providing
              you score data from some of our games.
            </div>
            <div className="sPageFive_content_two">
              With the help of the score data provided by us, you can fulfill
              various purposes such as making a leaderboard, giving chance to
              users to play battle games in real-time, gratifying users for
              playing games, and much more. With these, you can engage the users on
              your platform and keep them hooked effectively.
            </div>
            <div className="sPageFive_content_three">
              We provide you the score data with multiple layers of encryption,
              which makes the data extremely secure and hard-to-hackable as well.
              With the help of this, you can keep the players hooked to your
              platform with an exceptional gaming experience.
            </div>
          </Col>
        </Row>
        <Row justify="center" data-aos="fade-up">
          <Col xs={22} sm={20} md={23} lg={21} xl={20}>
            <div className="sPageFive_content_four">
              To provide you with a score from games, we require a post or get API from
              your end. If you are planning to gratify your users or are
              interested to license games for your website and facilitate the
              successful launch of addictive games on your platform, contact us
              right now.
            </div>
          </Col>
        </Row>
        <Row justify="center" data-aos="fade-up">
          <Col xs={22} sm={20} md={23} lg={21} xl={20}>
            <div className="sPageFive_content_five">
              To provide you with a score from games, we require a post or get API from
              your end. If you are planning to gratify your users or are
              interested to license games for your website and facilitate the
              successful launch of addictive games on your platform, contact us
              right now.
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PageFive;
