import React from "react";

import SittingBoyRight from "../../../Assets/Jungle-boy-sitting.webp";
import SittingBoyLeft from "../../../Assets/Sumo-sitting.webp";
import BenefitBtn1 from "../../../Assets/Benefits_Icon_1.webp";
import BenefitBtn2 from "../../../Assets/Benefits_Icon_2.webp";
import BenefitBtn3 from "../../../Assets/Benefits_Icon_3.webp";
import BenefitBtn4 from "../../../Assets/Benefits_Icon_4.webp";
import BenefitBtn5 from "../../../Assets/Benefits_Icon_5.webp";
import BenefitBtn6 from "../../../Assets/Benefits_Icon_6.webp";

import "./PageFive.less";
import { Col, Row } from "antd";

const PageFive = () => {
  return (
    <div className="pageFiveBackground" style={{ }}>
      <div className="page_five_benefits" style={{ textAlign: "center" }}>
        <img
          src={SittingBoyLeft}
          alt={"sittingBoysBtn"}
          style={{ width: 180, height: 180, marginTop: -50}}
          className="pageFive_sittingBoys"
        />
        BENEFITS OF HTML5 GAMES
        <img
          src={SittingBoyRight}
          alt={"sittingBoysBtn"}
          style={{ width: 180, height: 180, marginTop: -50 }}
          className=" pageFive_sittingBoys"
        />
      </div>
      <div className="page_five_benefits2" style={{ textAlign: "center" }}>
        {/* <img
          src={SittingBoyLeft}
          alt={"sittingBoysBtn"}
          style={{ width: 75, height: 75 }}
          className="pageFive_sittingBoys"
        /> */}
        <div className="pageFive_benifit_text">BENEFITS OF HTML5 GAMES</div>
        {/* <img
          src={SittingBoyRight}
          alt={"sittingBoysBtn"}
          style={{ width: 75, height: 75 }}
          className=" pageFive_sittingBoys"
        /> */}
      </div>

      <div className="pageFive_hBar_wrapper">
        <div className="pageFive_hBar">
          <div className="page_five_hEl" />
        </div>
      </div>

      <div className="pageFive_cards_div">
        <Row style={{ marginTop: 26 }} justify="space-around" gutter={[0, 30]}>
          <Col sm={20} md={16} lg={14} xl={10}>
            <div className="outerCard"
              style={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <div className="pageFive_img_card">
                <img src={BenefitBtn1} alt="BenefitBtn1" />
              </div>
              <div className="pageFive_card_body">
                <div className="mobileCard">
                  <div className="pageFive_img_card_mobile">
                    <img src={BenefitBtn1} alt="BenefitBtn1" width={60} height={60} />
                  </div>
                  <div>
                    <div className="page_five_title_one">One Game</div>
                    <div className="page_five_title_two">for Every Platform</div>
                  </div>
                </div>
                <div className="desktopCard">
                  <span className="page_five_title_one">One Game</span>
                  <span className="page_five_title_two">for Every Platform</span>
                </div>
                <p className="mobileCardContent">The cross-platform nature of HTML5, allows the games to run
                  on the majority of devices without affecting their performance. It
                  enables game developers to focus more on the quality of the games
                  instead of worrying about the performance.</p>
              </div>
            </div>
          </Col>
          <Col sm={20} md={16} lg={14} xl={10}>
            <div className="outerCard"
              style={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <div className="pageFive_img_card">
                <img src={BenefitBtn2} alt="BenefitBtn2" />
              </div>
              <div className="pageFive_card_body">
                <div className="mobileCard">
                  <div className="pageFive_img_card_mobile">
                    <img src={BenefitBtn2} alt="BenefitBtn1" width={60} height={60} />
                  </div>
                  <div>
                    <div className="page_five_title_one">Effective</div>
                    <div className="page_five_title_two">Solution</div>
                  </div>
                </div>
                <div className="desktopCard">
                  <span className="page_five_title_one">
                    Effective
                  </span>
                  <span className="page_five_title_two">Solutions</span>
                </div>
                <p className="mobileCardContent">HTML5 games are built on an efficient technology stack that
                  provides several benefits such as easy accessibility,
                  multi-platform compatibilities, seamless updates, and much more.
                  With these advantages, HTML5 games can be developed faster and get more
                  user engagement.</p>
              </div>
            </div>
          </Col>
          <Col sm={20} md={16} lg={14} xl={10}>
            <div className="outerCard"
              style={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <div className="pageFive_img_card">
                <img src={BenefitBtn3} alt="BenefitBtn3" />
              </div>
              <div className="pageFive_card_body">
                <div className="mobileCard">
                  <div className="pageFive_img_card_mobile">
                    <img src={BenefitBtn3} alt="BenefitBtn1" width={60} height={60} />
                  </div>
                  <div>
                    <div className="page_five_title_one">Easy</div>
                    <div className="page_five_title_two">Deployability</div>
                  </div>
                </div>
                <div className="desktopCard">
                  <span className="page_five_title_one">Easy</span>
                  <span className="page_five_title_two">Deployability</span>
                </div>
                <p className="mobileCardContent">HTML5 games offer you the flexibility to share and distribute them
                  in the most suitable ways. Thanks to the easy deployability
                  feature, once these games are successfully deployed, they are
                  ready to be played using any device. No further steps are
                  required.</p>
              </div>
            </div>
          </Col>
          <Col sm={20} md={16} lg={14} xl={10}>
            <div className="outerCard"
              style={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <div className="pageFive_img_card">
                <img src={BenefitBtn4} alt="BenefitBtn4" />
              </div>
              <div className="pageFive_card_body">
                <div className="mobileCard">
                  <div className="pageFive_img_card_mobile">
                    <img src={BenefitBtn4} alt="BenefitBtn1" width={60} height={60} />
                  </div>
                  <div>
                    <div className="page_five_title_one">More</div>
                    <div className="page_five_title_two">Revenue</div>
                  </div>
                </div>
                <div className="desktopCard">
                  <span className="page_five_title_one">More</span>
                  <span className="page_five_title_two">Revenue</span>
                </div>
                <p className="mobileCardContent">HTML5 games offer several innovative ways to increase your overall
                  revenue significantly. The cross-platform compatibility of HTML5
                  games makes them perfect to be promoted on various platforms,
                  which means greater revenues via in-game adverts.</p>
              </div>
            </div>
          </Col>
          <Col sm={20} md={16} lg={14} xl={10}>
            <div className="outerCard"
              style={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <div className="pageFive_img_card">
                <img src={BenefitBtn5} alt="BenefitBtn5" />
              </div>
              <div className="pageFive_card_body">
                <div className="mobileCard">
                  <div className="pageFive_img_card_mobile">
                    <img src={BenefitBtn5} alt="BenefitBtn1" width={60} height={60} />
                  </div>
                  <div>
                    <div className="page_five_title_one">Increase User</div>
                    <div className="page_five_title_two">Engagement</div>
                  </div>
                </div>
                <div className="desktopCard">
                  <span className="page_five_title_one">Increase User</span>
                  <span className="page_five_title_two">Engagement</span>
                </div>
                <p className="mobileCardContent">HTML5 games can do wonders for you and drive more traffic to your
                  platform beyond your expectations. These engaging and immersive
                  HTML5 games can also ensure that your users remain on your
                  platform for longer periods of time.</p>
              </div>
            </div>
          </Col>
          <Col sm={20} md={16} lg={14} xl={10}>
            <div className="outerCard"
              style={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <div className="pageFive_img_card">
                <img src={BenefitBtn6} alt="BenefitBtn6" />
              </div>
              <div className="pageFive_card_body">
                <div className="mobileCard">
                  <div className="pageFive_img_card_mobile">
                    <img src={BenefitBtn6} alt="BenefitBtn1" width={60} height={60} />
                  </div>
                  <div>
                    <div className="page_five_title_one">Easy</div>
                    <div className="page_five_title_two">Upgrades</div>
                  </div>
                </div>
                <div className="desktopCard">
                  <span className="page_five_title_one">Easy</span>
                  <span className="page_five_title_two">Upgrades</span>
                </div>
                <p className="mobileCardContent">Any upgrade to the HTML5 games is extremely easy, as HTML5 allows
                developers to code only once, and then deploy it to the various
                platforms, which enables developers to make the desired changes
                effortlessly for every platform.</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PageFive;
