import React from "react";

import PageOne from "./Pages/PageOne";
import PageTwo from "./Pages/PageTwo";

import "./TermsAndConditionsPage.less"

import { HeaderSize } from "../../Constants/global";

const TermsAndConditionsPage = () => {
  return (
    <div className="TermsAndConditionsPageStyle" style={{ paddingTop: HeaderSize.BottomPad }}>
      <PageOne />
      <PageTwo />
    </div>
  );
};

export default TermsAndConditionsPage;
