/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import ConnectUs from "../../Commons/ConnectUs";

import "./PageTwo.less";
import { Row, Col, Form, Input } from "antd";
import { useWindowSize } from "../../Commons/Common";


import Location from "../../../Assets/location icon.webp";
import Skype from "../../../Assets/facebook.webp";
import Mail from "../../../Assets/mail icon.webp";

const PageOne = () => {
    const [form] = Form.useForm();
    const [width, height] = useWindowSize();

    return (
        <div className="pageTwo_background">
            <div className="pageTwo_Title">
                CONTACT FORM
                {/* <hr style={{ width: 175, marginTop: 0, borderColor: "#21ce86", borderWidth: 0.1 }}></hr> */}
            </div>
            <div className="cuPageTwo_Div">
                <div className="cuCard_Div">
                    <div className="cuCard_Title_Div">
                        <p className="cuCard_Title_Text">REACH OUT TO US!</p>
                    </div>
                    <div className="cuCard_Text_Div">
                        <img src={Location} alt="" height="44px" />
                        <p className="cuCard_Text_1">Gamesfly Technologies Private Limited 5th Floor, H.B. Twin Tower, Netaji Subhash Place, Pitampura, Delhi-110034</p>
                    </div>
                    {/* <div className="cuCard_Text_Div">
                    <img src={Skype} alt="" height="50px"/>
                    <p className="cuCard_Text_1">Skype: nkcsfhughf7synh</p>
                </div> */}
                    <div className="cuCard_Text_Div">
                        <img src={Mail} alt="" height="44px" />
                        <p className="cuCard_Text_1 cuCard_Text_11">hello@freakxapps.com</p>
                    </div>
                </div>
                <div className="cuForm_Div">
                    <ConnectUs
                        className="connectUs_bg_ContactUs"
                        title="CONTACT FORM"
                        textAlign="center"
                        buttonText="Send Message"
                        buttonTextMobile="Send Message"
                    >
                    </ConnectUs>
                </div>
            </div>
            <div className="mapouter">
                <div className="gmap_canvas">
                    {/* <iframe class="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Freak X Games&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675046.741450864!2d73.83516549791658!3d25.905235848123713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0233842461f3%3A0xc4b67ce02079279f!2sFreak%20X%20Games%20-%20HTML5%20Game%20Development%20Company%20in%20Delhi!5e0!3m2!1sen!2sin!4v1694175539447!5m2!1sen!2sin" className="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" ></iframe>
                </div>
            </div>
            <div className="mapouterMobile">
                <div className="gmap_canvas">
                    <iframe className="gmap_iframe" width="100%" height="200" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675046.741450864!2d73.83516549791658!3d25.905235848123713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0233842461f3%3A0xc4b67ce02079279f!2sFreak%20X%20Games%20-%20HTML5%20Game%20Development%20Company%20in%20Delhi!5e0!3m2!1sen!2sin!4v1694175539447!5m2!1sen!2sin"></iframe>
                </div>
            </div>
        </div>
    );
};

export default PageOne;

{/* <div className="mapouter"><div className="gmap_canvas"><iframe class="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=600&amp;height=240&amp;hl=en&amp;q=University of Oxford&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://piratebay-proxys.com/">Piratebay</a></div><style>.mapouter{position:relative;text-align:right;width:100%;height:240px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:240px;}.gmap_iframe {height:240px!important;}</style></div> */ }