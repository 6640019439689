import React, { useState } from "react";

import Web_D from "../../../Assets/Web_D.webp";
import Web_L from "../../../Assets/Web_L 2.webp";
import Android_D from "../../../Assets/Android_D.webp";
import Android_L from "../../../Assets/Android_L 2.webp";
import Apple_D from "../../../Assets/Apple_D.webp";
import Apple_L from "../../../Assets/Apple_L 2.webp";
import Console_D from "../../../Assets/Console_D.webp";
import Console_L from "../../../Assets/Console_L 2.webp";
import PC_D from "../../../Assets/PC_D.webp";
import PC_L from "../../../Assets/PC_L 2.webp";

import { Colors } from "../../../Constants/Colors";

import "./PageThree.less";
import { Row, Col } from "antd";

import { useWindowSize } from "../../Commons/Common";

const gData = [
  { key: "1", name: "WEB", image: Web_L, hImage: Web_D },
  { key: "2", name: "ANDROID", image: Android_L, hImage: Android_D },
  { key: "3", name: "IOS", image: Apple_L, hImage: Apple_D },
  { key: "4", name: "CONSOLE", image: Console_L, hImage: Console_D },
  { key: "5", name: "PC", image: PC_L, hImage: PC_D },
];

const PageThree = () => {
  const [width, height] = useWindowSize();

  const [activeIndex, setActiveIndex] = useState(false);
  const [activeIndexPosition, setActiveIndexPosition] = useState();
  return (
    <div className="lPageThree_bg" style={{ overflow: "hidden" }}>
      <div className="lPageThree_Heading">
        OUR GAMES {width < 567 ? <br /> : ""}
        ARE PLAYABLE ON
      </div>
      <div className="lPageThree_hBar_wrapper">
        <div className="lPageThree_hBar">
          <div className="lPageThree_hEl" />
        </div>
      </div>

      <Row
        gutter={[30, 30]}
        justify="space-evenly"
        style={{ padding: "5px 0" }}
        data-aos="fade-up"
        data-aos-duration="750"
      >
        {gData &&
          gData.map((item) => {
            return (
              <>
                <Col
                  key={item.key}
                  onMouseEnter={() => {
                    setActiveIndex(true);
                    setActiveIndexPosition(item.key);
                  }}
                  onMouseLeave={() => setActiveIndex(false)}
                  data-aos="zoom-in"
                >
                  <div
                    className="lPageThree_card"
                    style={{
                      background:
                        activeIndex === true && activeIndexPosition === item.key
                          ? Colors.white
                          : "",
                    }}
                  >
                    <img
                      src={
                        activeIndex === true && activeIndexPosition === item.key
                          ? item.hImage
                          : item.image
                      }
                      alt=""
                      className="lPageThree_cImg"
                    />
                    <div
                      className="lPageThree_cContent"
                      style={{
                        color:
                          activeIndex === true &&
                          activeIndexPosition === item.key
                            ? Colors.primary
                            : Colors.white,
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                </Col>
              </>
            );
          })}
      </Row>
    </div>
  );
};

export default PageThree;
