import React from "react";

import RamImg from "../../../Assets/Ram.webp";

import "./PageSeven.less";

import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";

const PageSeven = () => {
  const [width, height] = useWindowSize();

  return (
    <div className="apageSeven_bg">
      <div className="aPageSeveBody">
        <div>
          <div className="aPageSeven_heading">OUR MISSION</div>
          <div className="aPageSeven_hBar_wrapper">
            <div className="aPageSeven_hBar">
              <div className="aPageSeven_hEl" />
            </div>
          </div>
        </div>
        <div className="ourMissionBody_Desktop">
          <Row justify="center" align="top" style={{
            marginTop: "50px",
            marginBottom: "-83px"
          }}>
            <Col xs={24} sm={14} md={15} lg={14} xl={11} data-aos="fade-right">
              <div className="aPageSeven_text_one">
                Working as an HTML5 game development company, we see the potential
                in the HTML5 technology for bringing more money and user engagement
                to businesses, and we want to make businesses such as yours realize
                the same potential by providing you with a single and unparalleled
                source of HTML5 games for your platform.
              </div>
              <div className="aPageSeven_text_two">
                Our core mission is to integrate our games on different platforms
                such as eCommerce, telecom operators, and OTT platforms to enhance
                their user engagement and drive higher revenues. On the other side,
                we focus on delivering an amazing experience to the individuals who
                enjoy playing different games.
              </div>
            </Col>
            <Col
              xs={24}
              sm={10}
              md={9}
              lg={9}
              xl={8}
              align="center"
              data-aos="fade-up"
            >
              <div className="aPageSeven_img">
                <img
                  src={RamImg}
                  alt="ramImg"
                  style={{ width: width > 1200 ? "120%" : width > 992 ? "100%" : width > 768 ? "80%" : "70%", height: width > 1200 ? "120%" : width > 992 ? "100%" : width > 768 ? "80%" : "50%", objectFit: "cover", zIndex: "1", marginTop: "-200px" }}
                />
              </div>
            </Col>
          </Row>
          <Row justify="center" align="middle" data-aos="fade-right">
            <Col>
              <div className="aPageSeven_text_three">
                Our core mission is to integrate our games on different platforms
                such as eCommerce, telecom operators, and OTT platforms to enhance
                their user engagement and drive higher revenues. On the other side,
                we focus on delivering an amazing experience to the individuals who
                enjoy playing different games.
              </div>
            </Col>
          </Row>
        </div>
        <div className="ourMissionBody_Mobile">
          <p data-aos="fade-right">
            <img data-aos="fade-up" src={RamImg} alt="" style={{ width: 200, height: 250, marginRight: "-25px",marginLeft: "-12px", marginTop: "-10px", float: "right" }} />
            Working as an HTML5 game development company, we see the potential
                in the HTML5 technology for bringing more money and user engagement
                to businesses, and we want to make businesses such as yours realize
                the same potential by providing you with a single and unparalleled
                source of HTML5 games for your platform.
                <br></br><br></br>
                Our core mission is to integrate our games on different platforms
                such as eCommerce, telecom operators, and OTT platforms to enhance
                their user engagement and drive higher revenues. On the other side,
                we focus on delivering an amazing experience to the individuals who
                enjoy playing different games.
                </p>
        </div>
      </div>
    </div>
  );
};

export default PageSeven;
