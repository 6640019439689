import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import { Pagination } from "swiper";

import CasualDressImg from "../../../Assets/casual dress code.webp"; // 1
import PaidLeavesImg from "../../../Assets/paid leaves.webp"; // 2
import FlexibleWorkingHoursImg from "../../../Assets/flexible working hours.webp"; // 3
import RemoteWorkImg from "../../../Assets/remote work.webp"; // 4
import GameRoomImg from "../../../Assets/game room.webp"; // 5
import LearningOpportunitiesImg from "../../../Assets/learning opportunities.webp"; // 6
import CulturalEventsImg from "../../../Assets/cultural events.webp"; // 7
import CompanyOutingsImg from "../../../Assets/company outings.webp"; // 8

import "./PageFour.less";
import { Col, Row } from "antd";

import { useWindowSize } from "../../Commons/Common";

const data = [
  {
    key: "1",
    image: CasualDressImg,
    heading: "Casual Dress Code",
  },
  {
    key: "2",
    image: PaidLeavesImg,
    heading: "Paid Leaves",
  },
  // {
  //   key: "3",
  //   image: FlexibleWorkingHoursImg,
  //   heading: "Flexible Working Hours",
  // },
  {
    key: "4",
    image: RemoteWorkImg,
    heading: "Remote Work",
  },
  // {
  //   key: "5",
  //   image: GameRoomImg,
  //   heading: "Game Room",
  // },
  {
    key: "6",
    image: LearningOpportunitiesImg,
    heading: "Learning Opportunities",
  },
  {
    key: "7",
    image: CulturalEventsImg,
    heading: "Cultural Events",
    // heading: "Cultural Events & Initiatives",
  },
  {
    key: "8",
    image: CompanyOutingsImg,
    heading: "Company Outings",
  },
];

const PageFour = () => {
  const [width, height] = useWindowSize();

  return (
    <div className="carPageFour_bg">

      <div className="carPage_Four_heading" style={{ textAlign: "center" }}>
        WHY JOIN {width <= 576 ? <br></br> : null}FREAK X GAMES?
      </div>

      <div className="carPage_Four_hBar_wrapper">
        <div className="carPage_Four_hBar">
          <div className="carPage_Four_hEl" />
        </div>
      </div>

      <div className="carPageFourDesktop">
        {data &&
          data.map((item) => {
            return (
              <div className="carPageFour_card">
                <div className="carPageFour_card_img">
                    <img
                      src={item.image}
                      alt="CasualDressImgBtn"
                      className=""
                      style={{ pointerEvents: "none", width: "100%", height: "100%", objectFit: "contain", alignSelf: "center", justifySelf: "center"}}
                    />
                </div>

                <div className="carPageFour_card_content">{item.heading}</div>
              </div>
            );
          })}
      </div>

      {/* <div className="carPageFourMobile">
        <Swiper
          // data-aos="fade-up"
          slidesPerView={2}
          // spaceBetween={0}
          slidesPerGroup={2}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
            bulletClass: "swiper-pagination-bullet",
            bulletActiveClass: "swiper-pagination-bullet-active",
          }}
          modules={[Autoplay, Pagination]}
          className="caPageFour_mySwiper"
        // style={{
        //   width: "100%",
        // }}
        // centeredSlides={true}
        >
          {data &&
            data.map((item) => {
              return (
                <SwiperSlide>
                  <div className="caPageFour_SwiperContainer">
                    <div className="caPageFour_card">
                      <div className="caPageFour_card_aOuter">
                        <div className="caPageFour_card_aInner">
                          <img
                            src={item.image}
                            alt="CasualDressImgBtn"
                            className="caPageFour_card_img"
                          />
                        </div>
                        <span className="caPageFour_card_aDots" />
                      </div>

                      <div className="caPageFour_card_content">{item.heading}</div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div> */}
    </div>
  );
};

export default PageFour;
