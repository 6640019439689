import React from "react";

import SumoOne from "../../../Assets/Sumo_01.webp";
import SumoTwo from "../../../Assets/Sumo_02.webp";
import SumoThree from "../../../Assets/Sumo_03.webp";
import SumoFour from "../../../Assets/Sumo_04.webp";
import SumoFive from "../../../Assets/Sumo_05.webp";
import SumoSix from "../../../Assets/Sumo_06.webp";

import "./PageTwo.less";
import { Row, Col } from "antd";
import { useWindowSize } from "../../Commons/Common";

const PageTwo = () => {
  const [width, height] = useWindowSize();

  return (
    <div className="lPageTwo_bg" style={{ overflow: "hidden" }}>
      <div className="lPageTwo_Heading">LICENSING PROCESS</div>
      <div className="lPageTwo_hBar_wrapper">
        <div className="lPageTwo_hBar">
          <div className="lPageTwo_hEl" />
        </div>
      </div>
      <div className="pageTwo_Body">
        <Row justify="space-evenly" gutter={[10, 30]} align="middle" style={{
          marginTop: "-20px",
          // marginBottom: "-50px"
        }}>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={9}
            xl={8}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="lPageTwo_img">
              <img
                src={SumoOne}
                alt="imgBtn"
                style={{ width: "130%", height: "110%", objectFit: "cover" }}
              />
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={11}
            xl={10}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="lPageTwo_content_wraper">
              <div className="lPageTwo_step_one">STEP 01</div>
              <div className="lPageTwo_subHeading_one">
                GRAB THE <br />
                REQUIREMENTS
              </div>

              <div className="lPageTwo_content_one">
                Before HTML5 games licensing, we closely work with our clients to
                take note of all the specific requirements. We focus on
                understanding the requirements like the type of games you want to
                add, the objective of adding games on your platform, and the ways
                you are planning to target your users.
              </div>
            </div>
          </Col>
        </Row>
        <Row justify="center" gutter={[10, 30]} align="middle" style={{
          marginTop: "-50px",
          // marginBottom: "-50px"
        }}>
          <Col
            xs={{ order: 2, span: 22 }}
            sm={{ order: 2, span: 20 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 1, span: 11 }}
            xl={{ order: 1, span: 10 }}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="lPageTwo_content_wraper">
              <div className="lPageTwo_step_two">STEP 02</div>
              <div className="lPageTwo_subHeading_two">
                SIGN THE <br />
                INITIAL DOCUMENTS
              </div>
              <div className="lPageTwo_content_two">
                Once we have understood your requirements, we proceed to the next
                step and sign an agreement with your brand to deliver top-notch
                game development services. Our agreement can offer you details of
                game requirements, licensing periods, and other important
                information.
              </div>
            </div>
          </Col>
          <Col
            xs={{ order: 1, span: 22 }}
            sm={{ order: 1, span: 20 }}
            md={{ order: 2, span: 12 }}
            lg={{ order: 2, span: 9 }}
            xl={{ order: 2, span: 8 }}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="lPageTwo_img">
              <img
                src={SumoTwo}
                alt="imgBtn"
                style={{ width: "130%", height: "100%", objectFit: "cover" }}
              />
            </div>{" "}
          </Col>
        </Row>
        <Row justify="space-evenly" gutter={[10, 30]} align="middle" style={{
          marginTop: "-20px",
          // marginBottom: "-50px"
        }}>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={9}
            xl={8}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="lPageTwo_img">
              <img
                src={SumoThree}
                alt="imgBtn"
                style={{ width: "130%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={11}
            xl={10}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="lPageTwo_content_wraper">
              <div className="lPageTwo_step_one">STEP 03</div>
              <div className="lPageTwo_subHeading_one">
                POC-SDK <br />
                INTEGRATION (IF NEEDED)
              </div>

              <div className="lPageTwo_content_one">
                After signing the agreement, our team gets started working on the
                games as per the requirements. We create and send a demo link
                before the actual launch of the games to ensure we meet all your
                requirements. Moreover, we can also integrate your SDK to embed
                your ads in the games.
              </div>
            </div>
          </Col>
        </Row>
        <Row justify="center" gutter={[10, 30]} align="middle" style={{
          marginTop: "-40px",
          // marginBottom: "-50px"
        }}>
          <Col
            xs={{ order: 2, span: 22 }}
            sm={{ order: 2, span: 20 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 1, span: 11 }}
            xl={{ order: 1, span: 10 }}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="lPageTwo_content_wraper">
              <div className="lPageTwo_step_two">STEP 04</div>
              <div className="lPageTwo_subHeading_two">
                QUALITY <br /> ASSURANCE
              </div>
              <div className="lPageTwo_content_two">
                Our team aims to deliver flawless HTML5 games that will
                provide your target users with an awesome gaming experience. For
                that, we conduct rigorous testing of the games to remove any
                potential bugs and offer you safe, secure, and bug-free games.
              </div>
            </div>
          </Col>
          <Col
            xs={{ order: 1, span: 20 }}
            sm={{ order: 1, span: 20 }}
            md={{ order: 2, span: 12 }}
            lg={{ order: 2, span: 9 }}
            xl={{ order: 2, span: 8 }}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="lPageTwo_img">
              <img
                src={SumoFour}
                alt="imgBtn"
                style={{ width: "130%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Col>
        </Row>
        <Row justify="space-evenly" gutter={[10, 30]} align="middle" style={{
          marginTop: "-80px",
          // marginBottom: "-50px"
        }}>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={9}
            xl={8}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="lPageTwo_img">
              <img
                src={SumoFive}
                alt="imgBtn"
                style={{ width: "130%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={11}
            xl={10}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="lPageTwo_content_wraper">
              <div className="lPageTwo_step_one">STEP 05</div>
              <div className="lPageTwo_subHeading_one">LAUNCH</div>

              <div className="lPageTwo_content_one">
                We understand that the launching process of the games can be
                challenging, so we provide you with quality assistance and enable
                you to successfully release the games on your platform. Our strong
                knowledge of the market can make the launch smooth and hassle-free
                for you.
              </div>
            </div>
          </Col>
        </Row>
        <Row justify="center" gutter={[10, 30]} align="middle" style={{
          marginTop: "-50px",
          marginBottom: "-40px"
        }}>
          <Col
            xs={{ order: 2, span: 22 }}
            sm={{ order: 2, span: 20 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 1, span: 11 }}
            xl={{ order: 1, span: 10 }}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="lPageTwo_content_wraper">
              <div className="lPageTwo_step_two">STEP 06</div>
              <div className="lPageTwo_subHeading_two">
                SUPPORT
                <br /> AND MAINTENANCE
              </div>
              <div className="lPageTwo_content_two">
                Our work doesn’t end with delivering and helping you in the launch of the
                games, but also our developers are always ready to fix the issues that
                may arise after games are live on your platform. In short, we offer
                you continuous support even after the game is launched on your
                platform.
              </div>
            </div>
          </Col>
          <Col
            xs={{ order: 1, span: 22 }}
            sm={{ order: 1, span: 20 }}
            md={{ order: 2, span: 12 }}
            lg={{ order: 2, span: 9 }}
            xl={{ order: 2, span: 8 }}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="lPageTwo_img">
              <img
                src={SumoSix}
                alt="imgBtn"
                style={{ width: "130%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="pageTwo_Body_Mobile">
        <Row justify="space-evenly" gutter={[10, 30]} align="middle">
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={9}
            xl={8}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="lPageTwo_img" style={{
              marginTop: "50px",
              marginBottom: "-50px"
            }}>
              <img
                src={SumoOne}
                alt="imgBtn"
                style={{ width: "90%", height: "90%", objectFit: "cover" }}
              />
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={11}
            xl={10}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="lPageTwo_content_wraper">
              <div className="lPageTwo_step_one">STEP 01</div>
              <div className="lPageTwo_subHeading_one">
                GRAB THE REQUIREMENTS
              </div>

              <div className="lPageTwo_content_one">
                Before HTML5 games licensing, we closely work with our clients to
                take note of all the specific requirements. We focus on
                understanding the requirements like the type of games you want to
                add, the objective of adding games on your platform and the ways
                you are planning to target your users.
              </div>
            </div>
          </Col>
        </Row>
        <Row justify="center" gutter={[10, 30]} align="middle">
          <Col
            xs={{ order: 2, span: 22 }}
            sm={{ order: 2, span: 20 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 1, span: 11 }}
            xl={{ order: 1, span: 10 }}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="lPageTwo_content_wraper">
              <div className="lPageTwo_step_two">STEP 02</div>
              <div className="lPageTwo_subHeading_two">
                SIGN THE INITIAL DOCUMENTS
              </div>
              <div className="lPageTwo_content_two">
                Once we have understood your requirements, we proceed to the next
                step and sign an agreement with your brand to deliver top-notch
                game development services. Our agreement can offer you details of
                game requirements, licensing periods, and other important
                information.
              </div>
            </div>
          </Col>
          <Col
            xs={{ order: 1, span: 22 }}
            sm={{ order: 1, span: 20 }}
            md={{ order: 2, span: 12 }}
            lg={{ order: 2, span: 9 }}
            xl={{ order: 2, span: 8 }}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="lPageTwo_img" style={{
              marginTop: "20px",
              marginBottom: "-50px"
            }}>
              <img
                src={SumoTwo}
                alt="imgBtn"
                style={{ width: "90%", height: "90%", objectFit: "cover" }}
              />
            </div>{" "}
          </Col>
        </Row>
        <Row justify="space-evenly" gutter={[10, 30]} align="middle">
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={9}
            xl={8}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="lPageTwo_img">
              <img
                src={SumoThree}
                alt="imgBtn"
                style={{ width: "80%", height: "90%", objectFit: "cover" }}
              />
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={11}
            xl={10}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="lPageTwo_content_wraper">
              <div className="lPageTwo_step_one">STEP 03</div>
              <div className="lPageTwo_subHeading_one">
                POC-SDK INTEGRATION (IF NEEDED)
              </div>

              <div className="lPageTwo_content_one">
                After signing the agreement, our team gets started working on the
                games as per the requirements. We create and send a demo link
                before the actual launch of the games to ensure we meet all your
                requirements. Moreover, we can also integrate your SDK to embed
                your ads in the games.
              </div>
            </div>
          </Col>
        </Row>
        <Row justify="center" gutter={[10, 30]} align="middle">
          <Col
            xs={{ order: 2, span: 22 }}
            sm={{ order: 2, span: 20 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 1, span: 11 }}
            xl={{ order: 1, span: 10 }}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="lPageTwo_content_wraper">
              <div className="lPageTwo_step_two">STEP 04</div>
              <div className="lPageTwo_subHeading_two">
                QUALITY ASSURANCE
              </div>
              <div className="lPageTwo_content_two">
                Our team aims to deliver you flawless HTML5 games that will
                provide your target users with an awesome gaming experience. For
                that, we conduct rigorous testing of the games to remove any
                potential bugs and offer you safe, secure, and bug-free games.
              </div>
            </div>
          </Col>
          <Col
            xs={{ order: 1, span: 22 }}
            sm={{ order: 1, span: 20 }}
            md={{ order: 2, span: 12 }}
            lg={{ order: 2, span: 9 }}
            xl={{ order: 2, span: 8 }}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="lPageTwo_img" style={{
              marginTop: "20px",
              marginBottom: "-50px"
            }}>
              <img
                src={SumoFour}
                alt="imgBtn"
                style={{ width: "90%", height: "90%", objectFit: "cover" }}
              />
            </div>
          </Col>
        </Row>
        <Row justify="space-evenly" gutter={[10, 30]} align="middle">
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={9}
            xl={8}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="lPageTwo_img" style={{
              marginTop: "30px",
              marginBottom: "-50px"
            }}>
              <img
                src={SumoFive}
                alt="imgBtn"
                style={{ width: "90%", height: "90%", objectFit: "cover" }}
              />
            </div>
          </Col>
          <Col
            xs={22}
            sm={20}
            md={12}
            lg={11}
            xl={10}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="lPageTwo_content_wraper">
              <div className="lPageTwo_step_one">STEP 05</div>
              <div className="lPageTwo_subHeading_one">LAUNCH</div>

              <div className="lPageTwo_content_one">
                We understand that the launching process of the games can be
                challenging, so we provide you with quality assistance and enable
                you to successfully release the games on your platform. Our strong
                knowledge of the market can make the launch smooth and hassle-free
                for you.
              </div>
            </div>
          </Col>
        </Row>
        <Row justify="center" gutter={[10, 30]} align="middle">
          <Col
            xs={{ order: 2, span: 22 }}
            sm={{ order: 2, span: 20 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 1, span: 11 }}
            xl={{ order: 1, span: 10 }}
            align="flex-start"
            data-aos={width < 768 ? "fade-up" : "fade-right"}
          >
            <div className="lPageTwo_content_wraper">
              <div className="lPageTwo_step_two">STEP 06</div>
              <div className="lPageTwo_subHeading_two">
                SUPPORT AND MAINTENANCE
              </div>
              <div className="lPageTwo_content_two">
                Our work doesn’t end with delivering and helping you in the launch of the
                games, but also our developers are always ready to fix the issues that
                may arise after games are live on your platform. In short, we offer
                you continuous support even after the game is launched on your
                platform.
              </div>
            </div>
          </Col>
          <Col
            xs={{ order: 1, span: 22 }}
            sm={{ order: 1, span: 20 }}
            md={{ order: 2, span: 12 }}
            lg={{ order: 2, span: 9 }}
            xl={{ order: 2, span: 8 }}
            align="center"
            data-aos={width < 768 ? "fade-up" : "fade-left"}
          >
            <div className="lPageTwo_img" style={{
              marginTop: "20px",
              marginBottom: "-50px"
            }}>
              <img
                src={SumoSix}
                alt="imgBtn"
                style={{ width: "90%", height: "90%", objectFit: "cover" }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PageTwo;
